import { formatAddressString } from "../common/utils";
import { AddressOption, AutocompleteItem, MyhouseUnit } from "../interfaces/frontend";
import {
  AddReportPictureCommand,
  AddressFilter,
  AddressGroupedListItem,
  CreateReportCommand,
  DeleteDescriptionPictureCommand,
  DeleteReportPictureCommand,
  InspectorReport,
  PictureRef,
  ReportListItem,
  SearchResult,
  UpdateDescriptionPictureCommand,
  UpdateHousePictureCommand,
} from "../interfaces/models";
import ApiService from "./ApiService";

const ReportService = {
  getReportList: async (
    filter: string,
    skip?: number,
    take?: number,
    noHistory?: boolean,
    addressFilter?: AddressFilter,
    allStates?: boolean,
    personalOnly?: boolean
    
  ) => {
    let url = `/api/report?filter=${filter || ""}&skip=${skip || ""}&take=${take || ""}`;
    if (noHistory) url += "&noHistory=true";
    if (addressFilter) {
      url += "&AddressFilter=" + addressFilter;
    }
    if (allStates) url += "&allStates=true";
    if (personalOnly) url += "&personalOnly=true";

    return await ApiService.get<SearchResult<ReportListItem>>(url);
  },

  getGroupedReportList: async (filter: string, skip?: number, take?: number) => {
    const url = `/api/report/grouped?filter=${filter || ""}&skip=${skip || ""}&take=${take || ""}`;
    return await ApiService.get<SearchResult<AddressGroupedListItem<ReportListItem>>>(url);
  },

  getReport: async (reportId: string, deviceId: string) => {

    return await ApiService.get<InspectorReport>(`/api/report/${reportId}`, { "DeviceId" : deviceId });
  },

  uploadReport: async (report: CreateReportCommand) => {
    return await ApiService.post<string>("/api/report", report);
  },

  uploadPicture: async (picture: AddReportPictureCommand) => {
    return await ApiService.post<PictureRef>("/api/report/picture", picture);
  },

  uploadDescriptionPicture: async (picture: UpdateDescriptionPictureCommand) => {
    return await ApiService.post<PictureRef>("/api/report/description/picture", picture);
  },

  uploadHousePicture: async (picture: UpdateHousePictureCommand) => {
    return await ApiService.post<PictureRef>("/api/report/house/picture", picture);
  },

  approveReport: async (reportId: string) => {
    return await ApiService.post(`/api/report/approve/${reportId}`);
  },

  unapproveReport: async (reportId: string) => {
    return await ApiService.post(`/api/report/unapprove/${reportId}`);
  },

  getCustomerReportList: async (filter: any, skip = 0, take = 10) => {
    return await ApiService.get<SearchResult<ReportListItem>>(
      `/api/report/customer?filter=${filter || ""}&skip=${skip || ""}&take=${take || ""}`
    );
  },

  getCustomerReport: async (reportId: string) => {
    return await ApiService.get<InspectorReport>(`/api/report/customer/${reportId}`);
  },

  transferReport: async (reportId: string, employeeId: string) => {
    return await ApiService.post(`/api/report/transfer/${reportId}?inspectorId=${employeeId}`);
  },

  removeReport: async (reportId: string) => {
    return await ApiService.delete(`/api/report/${reportId}`);
  },

  getAutocompleteValues: async (
    inputValue: string,
    cursorPosition: number,
    signal: AbortSignal | null
  ): Promise<AddressOption[]> => {
    try {
      if (!inputValue || inputValue.length < 3) return [];
      const options = await ApiService.get<AutocompleteItem[]>(
        `${
          process.env.REACT_APP_MYHOUSE_API_URL
        }/api/autocomplete?text=${inputValue}&cursorPosition=${cursorPosition}&startFromBuildings=${false}`, undefined,
        signal
        //`/api/autocomplete?text=${inputValue}&cursorPosition=${inputValue.length}`
      );

      if (options && options.length) {
        return options.map((x) => {
          const option: AddressOption = {
            value: x.UnitId,
            label: x.Address.Street
              ? formatAddressString(
                  {
                    street: x.Address.Street,
                    houseNo: x.Address.HouseNo,
                    floor: x.Address.Floor,
                    doorNumber: x.Address.DoorNumber,
                    postalCode: x.Address.PostalCode,
                    city: x.Address.City,
                  },
                  false
                )
              : x.Text,
            cursorPosition: x.CursorPosition,
            houseData: {
              houseId: x.UnitId || "",
              postcode: x.Address.PostalCode,
              city: x.Address.City,
              area: 0,
              livingArea: 0,
              basementArea: 0,
              buildYear: 0,
              rooms: 0,
              groundArea: 0,
              unitArea: 0,
              address: formatAddressString(
                {
                  street: x.Address.Street,
                  houseNo: x.Address.HouseNo,
                  floor: x.Address.Floor,
                  doorNumber: x.Address.DoorNumber,
                  postalCode: x.Address.PostalCode,
                  city: x.Address.City,
                },
                true
              ),
              reportDate: new Date(),
              latitude: x.Address.Latitude,
              longitude: x.Address.Longtitude,
              caseNumber: 0,
              owner: "",
              ownerLastName: "",
              ownerEmail: "",
              ownerPhone: "",
              cadastral: "",
              energyLabel: "",
              municipality: "",
              picture: "",
              propertyType: "",
              unitType: "",
            },
          };
          return option;
        });
      }
    } catch (error: unknown) {
      console.log(error);
    }
    return [];
  },

  getHouseInfo: async (houseId: string) => {
    return await ApiService.get<MyhouseUnit>(`${process.env.REACT_APP_MYHOUSE_API_URL}/api/house/${houseId}`);
  },
  removePicture: async (picture: DeleteReportPictureCommand) => {
    const url = `/api/report/picture`;
    return await ApiService.delete(url, picture);
  },

  removeDescriptionPicture: async (picture: DeleteDescriptionPictureCommand) => {
    const url = `/api/report/description/picture`;

    return await ApiService.delete(url, picture);
  },
};

export default ReportService;
