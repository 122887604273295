import React, { useEffect, useRef, useState } from "react";
import { Button, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import GeneralDialog from "../../shared/GeneralDialog";
import PdfGenerator from "../../shared/modules/pdfgen/PdfGenerator";
import { useReportActions, useReportState } from "../reportActions";
import PdfDocumentLayout from "../../shared/modules/pdfgen/PdfDocumentLayout";
import { pdf } from "@react-pdf/renderer";
import { useUser } from "../../common/hooks";
import LoadingOverlay from "../../shared/LoadingOverlay";
import { transformReport } from "../../common/utils";
import { ca } from "date-fns/locale";

const useStyles = makeStyles((theme: Theme) => ({
  reportContent: {
    position: "fixed",
    zIndex: 1300,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "rgba(7,7,7,0.8)",
    flexDirection: "column",
    // "-webkit-overflow-scrolling": "touch",
    // overflowY: "scroll"
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    paddingRight: 20,
    position: "fixed",
    zIndex: 9999,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  button: {
    color: "#fff",
  },
  acceptButton: {
    color: "#5A5",
  },
}));

export type PdfModalProps = {
  handleApprove: () => Promise<void>;
  shown: boolean;
  needApprove: boolean;
  handleClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
};


export const generatePdf = async (report: any, user: any) => {
  try {
    const reportData = await transformReport(report, user);
    const blob = await pdf(<PdfDocumentLayout report={reportData} />).toBlob();
    const file = new File([blob], report.id + ".pdf", {
      type: blob.type,
    });
    return file;
  } catch (error) {
    console.log(error);
  }
};

const PdfModal = (props: PdfModalProps) => {
  const classes = useStyles(props);
  const [approvalDialogShown, setApprovalDialogShown] = useState(false);
  const report = useReportState();
  const user = useUser();
  const reportActions = useReportActions();

  const [loading, setLoading] = useState(false);

  const blobRef = useRef<Blob | null>();

  useEffect(() => {
    return () => {
      blobRef.current = undefined;
    };
  }, []);

  const showApprovalDialog = () => {
    setApprovalDialogShown(true);
  };

  const closeApprovalDialog = async (value?: boolean) => {
    setApprovalDialogShown(false);
    if (value) {
      setLoading(true);
      try {
        const result = await saveDoc(report.id);
        console.log("Report " + result + " uploaded");
        props.handleApprove();
      } catch (error) {
        alert("Report is not uploaded. Try again");
      } finally {
        setLoading(false);
      }
    }
  };

  const shareDoc = async (fileName: string | undefined) => {
    try {
      if (!blobRef.current) {
        setLoading(true);
        const reportData = await transformReport(report, user);
        blobRef.current = await pdf(<PdfDocumentLayout report={reportData} />).toBlob();
      }

      const file = new File([blobRef.current], fileName + ".pdf", {
        type: blobRef.current.type,
      });
      await reportActions.saveDoc(report.id, file);

      const shareData: ShareData = {
        files: [
          new File([blobRef.current], fileName + ".pdf" ?? "report.pdf", {
            type: blobRef.current.type,
          }),
        ],
      };
      if (navigator.canShare(shareData)) {
        try {
          await navigator.share(shareData);
        } catch (error: any) {
          console.log(error);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const saveDoc = async (fileName: string) => {
    try {
      setLoading(true);
      if (!blobRef.current) {
        const reportData = await transformReport(report, user);
        blobRef.current = await pdf(<PdfDocumentLayout report={reportData} />).toBlob();
      }

      const file = new File([blobRef.current], fileName + ".pdf", {
        type: blobRef.current.type,
      });
      await reportActions.saveDoc(report.id, file);
    } finally {
      setLoading(false);
    }
  };

  return props.shown ? (
    <div className={classes.reportContent} style={{ overflowY: "scroll", WebkitOverflowScrolling: "touch" }}>
      <div className={classes.buttonsWrapper}>
        <>
          {!props.needApprove ? (
            <Button
              disabled={loading}
              onClick={async () => {
                await shareDoc(report.id);
              }}
            >
              Del
            </Button>
          ) : (
            <>
              {!!props.handleApprove && (
                <Button className={classes.acceptButton} disabled={loading} onClick={showApprovalDialog}>
                  Godkend
                </Button>
              )}
            </>
          )}
        </>

        <Button className={classes.button} onClick={props.handleClose}>
          Tilbage
        </Button>
      </div>

      <PdfGenerator />
      <GeneralDialog
        open={approvalDialogShown}
        dialogTitle={"Ønsker du at godkende rapporten?"}
        dialogText={
          "Du kan ikke selv låse rapporten op, hvis den godkendes. Admin kan hjælpe, hvis det bliver nødvendigt."
        }
        handleClose={closeApprovalDialog}
      />
      <LoadingOverlay loading={loading} />
    </div>
  ) : null;
};

export default PdfModal;
