import { Common, User } from "../constants/actionTypes";
import { handleError } from "../common/commonActions";
import { LocalStorageItems } from "../constants/appConstants";
import { updateLocalData, getLocalData, removeLocalData } from "../common/utils";
import UserService from "../services/UserService";
import { useDispatch } from "react-redux";
import {
  CraftsmanType,
  CreateCompanyCommand,
  ManageTeamCommand,
  UpdateCompanyCommand,
  UpdateUserCommand,
  UserRole,
} from "../interfaces/models";
import { UserState } from "./userReducer";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../store/store";
import { AnyAction } from "redux";
import ApiService from "../services/ApiService";
import { AppError } from "../interfaces/frontend";

export const useUserActions = () => {
  const dispatch: ThunkDispatch<AppState, any, AnyAction> = useDispatch();

  return {
    login: (email: string, password: string) => dispatch(login(email, password)),
    logout: () => dispatch(logout()),
    authenticateLocal: () => dispatch(authenticateLocal()),
    getProfile: () => dispatch(getProfile()),
    checkProfile: () => dispatch(checkProfile()),
    updateProfile: (user: UpdateUserCommand) => dispatch(updateProfile(user)),
    changePassword: (currentPassword: string, newPassword: string) =>
      dispatch(changePassword(currentPassword, newPassword)),
    getRating: () => dispatch(getRating()),
    getRatingValues: (skip: number, take: number) => dispatch(getRatingValues(skip, take)),
    getTeam: () => dispatch(getTeam()),
    manageTeam: (command: ManageTeamCommand) => dispatch(manageTeam(command)),
    getCompanies: (
      query: string,
      filter: CraftsmanType,
      skip: number,
      take: number,
      sortField: string,
      sortDescending: boolean,
      excludeTeam?: boolean
    ) => dispatch(getCompanies(query, filter, skip, take, sortField, sortDescending, excludeTeam)),
    createCompany: (cmd: CreateCompanyCommand) => dispatch(createCompany(cmd)),
    updateCompany: (cmd: UpdateCompanyCommand) => dispatch(updateCompany(cmd)),
    sendForgotPasswordRequest: (email: string) => dispatch(sendForgotPasswordRequest(email)),
    validateToken: (email: string, token: string) => dispatch(validateToken(email, token)),
    resetPassword: (token: string, newPassword: string, email: string) => dispatch(resetPassword(token, newPassword, email)),
  };
};

export const login = (email: string, password: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: User.LOGIN_REQUEST });
  try {
    const user = await UserService.login(email, password);

    //filter out not inspector users
    if (user && [UserRole.Customer, UserRole.Craftsman, UserRole.None].includes(user.role)) {
      throw new Error("Login failed");
    }

    if (user) {
      await updateLocalData(LocalStorageItems.User, user);
      dispatch({ type: User.LOGIN_SUCCEEDED, payload: user });
      dispatch(performAfterLoginActions);
    }
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, {
      type: User.LOGIN_FAILED,
    });
  }
};

export const authenticateLocal = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  const user = await getLocalData<UserState>(LocalStorageItems.User);
  dispatch({ type: User.AUTH_LOCAL_NOT_FOUND });

  //filter out not inspector users
  if (user && [UserRole.Customer, UserRole.Craftsman, UserRole.None].includes(user.role)) {
    await dispatch(logout());
    return;
  }

  if (user?.accessToken) {
    dispatch({ type: User.AUTH_LOCAL, payload: user });
    dispatch(performAfterLoginActions);
    return true;
  }
  dispatch({ type: User.AUTH_LOCAL_NOT_FOUND });
  return false;
};

const performAfterLoginActions = async (
  dispatch: ThunkDispatch<AppState, any, AnyAction>,
  getState: () => AppState
) => {
  const user = getState().user;
  try {
    dispatch({ type: User.GET_OWN_COMPANY });
    const company = await UserService.getOwnCompany();
    dispatch({ type: User.GET_OWN_COMPANY_SUCCEEDED, payload: company });
  } catch (error) {
    dispatch({ type: User.USER_OPERATION_FAILED, payload: error });
  }

  if (user.role === UserRole.Craftsman) {
    try {
      dispatch({ type: User.GET_TEAM });
      const team = await UserService.getTeam();
      dispatch({ type: User.GET_TEAM_SUCCEEDED, payload: team });
    } catch (error) {
      dispatch({ type: User.USER_OPERATION_FAILED, payload: error });
    }
  }

  //dispatch({ type: Common.START_SIGNALR });
};

export const logout = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  return Promise.all([
    removeLocalData(LocalStorageItems.User),
    removeLocalData(LocalStorageItems.Report),
    removeLocalData(LocalStorageItems.Template),
  ]).then(() => {
    dispatch({ type: User.LOGOUT });
    //dispatch({ type: Common.STOP_SIGNALR });
    window.history.replaceState(undefined, "", "/");
  });
};

export const getProfile = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    const profile = await UserService.getProfile();
    dispatch({ type: User.GET_PROFILE_SUCCEEDED, payload: profile });
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, {
      type: User.GET_PROFILE_FAILED,
      payload: error,
      error: true,
    });
  }
};

export const checkProfile = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    const valid = await UserService.checkProfile();
    dispatch({ type: User.CHECK_PROFILE, payload: valid });
    return valid;
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, {
      type: User.GET_PROFILE_FAILED,
      payload: error,
      error: true,
    });
  }
};

export const updateProfile = (user: UpdateUserCommand) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    dispatch({ type: User.UPDATE_PROFILE });
    const profile = await UserService.updateProfile(user);
    const localUser = await getLocalData<UserState>(LocalStorageItems.User);
    await updateLocalData(LocalStorageItems.User, {
      ...localUser,
      ...profile,
    });
    dispatch({ type: User.UPDATE_PROFILE_SUCCEEDED, payload: profile });
    return true;
  } catch (error: unknown) {
    dispatch({ type: User.UPDATE_PROFILE_FAILED, payload: error });
    throw error;
  }
};

export const changePassword =
  (currentPassword: string, newPassword: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    try {
      dispatch({ type: User.CHANGE_PASSWORD });
      const profile = await UserService.changePassword(currentPassword, newPassword);
      dispatch({ type: User.CHANGE_PASSWORD_SUCCEEDED, payload: profile });
    } catch (error: unknown) {
      dispatch({ type: User.CHANGE_PASSWORD_FAILED, payload: error });
      throw error;
    }
  };

const getRating = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    dispatch({ type: User.GET_RATING });

    const rating = await UserService.getRating();
    dispatch({ type: User.GET_RATING_SUCCEEDED, payload: rating });
  } catch (error: unknown) {
    dispatch({ type: User.USER_OPERATION_FAILED, payload: error });
    throw error;
  }
};

const getRatingValues = (skip: number, take: number) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    dispatch({ type: User.GET_RATING_VALUES });

    const values = await UserService.getRatingValues(skip, take);
    dispatch({ type: User.GET_RATING_VALUES_SUCCEEDED, payload: values });
  } catch (error: unknown) {
    dispatch({ type: User.USER_OPERATION_FAILED, payload: error });
    throw error;
  }
};

const getTeam = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    dispatch({ type: User.GET_TEAM });
    const team = await UserService.getTeam();
    dispatch({ type: User.GET_TEAM_SUCCEEDED, payload: team });
  } catch (error: unknown) {
    dispatch({ type: User.USER_OPERATION_FAILED, payload: error });
    throw error;
  }
};

const manageTeam = (command: ManageTeamCommand) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    dispatch({ type: User.MANAGE_TEAM });

    await UserService.manageTeam(command);
    const team = await UserService.getTeam();
    dispatch({ type: User.GET_TEAM_SUCCEEDED, payload: team });
  } catch (error: unknown) {
    dispatch({ type: User.USER_OPERATION_FAILED, payload: error });
    throw error;
  }
};

const getCompanies =
  (
    query: string,
    filter: CraftsmanType,
    skip: number,
    take: number,
    sortField: string,
    sortDescending: boolean,
    excludeTeam?: boolean
  ) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    try {
      dispatch({ type: User.GET_COMPANIES });
      const result = await UserService.getCompanies(query, filter, sortField, sortDescending, excludeTeam, skip, take);
      dispatch({ type: User.GET_COMPANIES_SUCCEEDED, payload: result });
      return result;
    } catch (error: unknown) {
      dispatch({ type: User.USER_OPERATION_FAILED, payload: error });
      throw error;
    }
  };

const createCompany = (cmd: CreateCompanyCommand) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    return await ApiService.post("/api/company", cmd);
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};

const updateCompany = (cmd: UpdateCompanyCommand) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    await ApiService.put("/api/company", cmd);
    const team = await UserService.getTeam();
    dispatch({ type: User.GET_TEAM_SUCCEEDED, payload: team });
    return team;
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};

const sendForgotPasswordRequest = (email: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    dispatch({ type: User.SEND_FORGOT_PASSWORD_REQUEST });
    await ApiService.post("/api/account/forgotpwd", { email });
    dispatch({ type: User.SEND_FORGOT_PASSWORD_REQUEST_SUCCEEDED });
  } catch (error: unknown) {
    handleError(error as AppError, dispatch, {
      type: User.SEND_FORGOT_PASSWORD_REQUEST_FAILED,
    });
  }
};

const validateToken = (email: string, token: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    await ApiService.post(`/api/account/validate`, { email, token });
    return true;
  } catch (error: unknown) {
    handleError(error as AppError, dispatch, {
      type: User.RESET_PASSWORD_FAILED,
    });

    throw error;
  }
};

const resetPassword =
  (token: string, newPassword: string, email: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    try {
      dispatch({ type: User.RESET_PASSWORD });
      await ApiService.post("/api/account/resetpwd", { token, newPassword, email });
      dispatch({ type: User.RESET_PASSWORD_SUCCEEDED });
    } catch (error: unknown) {
      handleError(error as AppError, dispatch, {
        type: User.RESET_PASSWORD_FAILED,
      });
      throw error;
    }
  };
