import React, { useEffect, useState } from "react";
import DataTable, { DataTableProps } from "../../../shared/DataTable";
import { format } from "date-fns";
import daLocale from "date-fns/locale/da";
import ReportLayout from "../../../pages/components/ReportLayout";
import {
  Button,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useReportActions, useReportState } from "../../../pages/reportActions";
import { useCommonState } from "../../../common/commonActions";
import { AddressFilter, CompanyRole, Employee, ReportListItem, UserRole } from "../../../interfaces/models";
import { ChangeContext } from "../../../pages/CreateReportPage";
import {
  AssignmentIndOutlined,
  DeleteForever,
  MoreVert,
  PictureAsPdfOutlined,
  Preview,
  SettingsSuggest,
} from "@mui/icons-material";
import GeneralDialog from "../../../shared/GeneralDialog";
import StyledDialog, { StyledDialogActions, StyledDialogContent } from "../../../shared/StyledDialog";
import { useUser } from "../../../common/hooks";
import { generatePdf } from "../../../pages/modals/PdfModal";
import NotificationBar from "../../../shared/Notification";

const ReportActionsMenu = (props: {
  viewReport: (report: ReportListItem | undefined) => Promise<any>;
  assignReport: (report: ReportListItem | undefined) => void;
  removeReport: (user: ReportListItem | undefined) => void;
  downloadPdf: (report: ReportListItem | undefined) => Promise<any>;
  generatePdf: (report: ReportListItem | undefined) => Promise<any>;
  item?: ReportListItem;
  busy?: boolean;
  role: UserRole;
}) => {
  const { item } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLLIElement | HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewReport = () => {
    setAnchorEl(null);
    props.viewReport(item);
  };

  const assignReport = () => {
    setAnchorEl(null);
    props.assignReport(item);
  };

  const removeReport = () => {
    setAnchorEl(null);
    props.removeReport(item);
  };

  const downloadPdf = () => {
    setAnchorEl(null);
    props.downloadPdf(item);
  };

  const generatePdf = () => {
    setAnchorEl(null);
    props.generatePdf(item);
  };

  return (
    <div>
      <StyledSmallIconButton
        size="small"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert fontSize="inherit" />
      </StyledSmallIconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={viewReport}>
          <ListItemIcon>
            <Preview />
          </ListItemIcon>
          <ListItemText>Vis</ListItemText>
        </MenuItem>
        {props.role === UserRole.Inspector && !item?.approved && (
          <MenuItem onClick={assignReport}>
            <ListItemIcon>
              {/* <TransferWithinAStation /> */}
              <AssignmentIndOutlined />
            </ListItemIcon>
            <ListItemText>Assign to inspector</ListItemText>
          </MenuItem>
        )}
        {item?.approved && (
          <div>
            <MenuItem onClick={downloadPdf}>
              <ListItemIcon>
                <PictureAsPdfOutlined />
              </ListItemIcon>
              <ListItemText>Indlæs PDF</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={generatePdf} disabled={props.busy}>
              <ListItemIcon>
                <SettingsSuggest />
              </ListItemIcon>
              <ListItemText>Generate new PDF (long operation)</ListItemText>
            </MenuItem>
          </div>
        )}
        {!item?.approved && (
          <div>
            <Divider />
            <MenuItem onClick={removeReport}>
              <ListItemIcon>
                <DeleteForever />
              </ListItemIcon>
              <ListItemText>Slet</ListItemText>
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
};

const StyledSmallIconButton = styled(IconButton)({
  sizeSmall: {
    padding: 0,
  },
});

const ReportDataTable = (props: DataTableProps<ReportListItem>) => <DataTable {...props} />;

const Reports = (props: { viewState?: "list" | "report"; noBackButton?: boolean; onShowReport?: () => void }) => {
  const [reportView, setReportView] = useState(false);
  const reportState = useReportState();
  const reportActions = useReportActions();
  const commonState = useCommonState();
  const [showAssignDialog, setShowAssignDialog] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ReportListItem | undefined>(undefined);
  const [refetch, setRefetch] = useState(false);
  const user = useUser();
  const [notification, setNotification] = useState<{
    notificationShown: boolean;
    notificationText: string;
    notificationType: "error" | "success";
  }>({ notificationShown: false, notificationText: "", notificationType: "error" });

  useEffect(() => {
    reportActions.clearReportList();
    return () => {
      reportActions.clearReport();
    };
  }, []);

  //external back button
  useEffect(() => {
    if (props.viewState === "list") {
      setReportView(false);
      reportActions.clearReport();
    }
  }, [props.viewState]);

  const getReport = async (report: ReportListItem | undefined) => {
    try {
      if (report) {
        await reportActions.getReport(report.id);
        setReportView(true);
        props.onShowReport && props.onShowReport();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReportList = async (
    query: string,
    filter: any,
    skip: number,
    take: number,
    sortField: string,
    sortDescending: boolean
  ) => {
    await reportActions.getReportList(query, skip, take, true, AddressFilter.All, true);
    setRefetch(false);
  };

  const assignReport = (report: ReportListItem | undefined) => {
    if (report) {
      setSelectedReport(report);
      setShowAssignDialog(true);
    }
  };

  const removeReport = async (report: ReportListItem | undefined) => {
    if (report) {
      setSelectedReport(report);
      setShowRemoveDialog(true);
    }
  };

  const handleAssignDialogClose = async (dialogResult?: string) => {
    setShowAssignDialog(false);
    if (dialogResult && selectedReport) {
      try {
        await reportActions.assignReport(selectedReport.id, dialogResult);
        setRefetch(true);
      } catch (error) {
        setNotification({
          notificationShown: true,
          notificationText: "Failed to assign report.",
          notificationType: "error",
        });
      }
    }
  };

  const handleRemoveDialogClose = async (dialogResult?: boolean) => {
    setShowRemoveDialog(false);
    if (dialogResult && selectedReport) {
      try {
        await reportActions.removeReport(selectedReport.id);
        setRefetch(true);
      } catch (error) {
        setNotification({
          notificationShown: true,
          notificationText: "Failed to remove report.",
          notificationType: "error",
        });
      }
    }
  };

  const downloadPdf = async (report: ReportListItem | undefined) => {
    if (report) {
      const fullReport = await reportActions.getReport(report.id);
      if (!fullReport) {
        setNotification({
          notificationShown: true,
          notificationText: "Failed to get report.",
          notificationType: "error",
        });
        return;
      }
      if (!fullReport.pdfLink) {
        setNotification({
          notificationShown: true,
          notificationText: "PDF not found. Generate a new one.",
          notificationType: "error",
        });
        return;
      }
      const response = await fetch(fullReport.pdfLink);
      if (!response.ok) {
        setNotification({
          notificationShown: true,
          notificationText: "PDF failed to generate.",
          notificationType: "error",
        });
        return;
      }
      const blob = await response.blob();

      if (blob) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fullReport.data.address + ", " + fullReport.data.city + ".pdf"); // or any other filename you want
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const generateNewPdf = async (report: ReportListItem | undefined) => {
    if (report) {
      try {
        const fullReport = await reportActions.getReport(report.id);
        const pdf = await generatePdf(fullReport, user);
        if (!pdf) {
          setNotification({
            notificationShown: true,
            notificationText: "Failed to generate PDF.",
            notificationType: "error",
          });
          return;
        }

        await reportActions.saveDoc(report.id, pdf);
        setNotification({
          notificationShown: true,
          notificationText: "PDF generated and uploaded.",
          notificationType: "success",
        });
      } catch (error) {
        setNotification({
          notificationShown: true,
          notificationText: "Failed to upload PDF.",
          notificationType: "error",
        });
        return;
      }
    }
  };

  const handleNotificationClose = () => {
    setNotification({ notificationShown: false, notificationText: "", notificationType: "error" });
  };

  return (
    <>
      {!reportView ? (
        <ReportDataTable
          columns={[
            { label: "Case number", key: "caseNumber" },
            { label: "Address", key: "name", transform: (val) => val || "No address" },
            { label: "Inspector", key: "createdBy" },
            { label: "Status", key: "approved", transform: (val) => (val ? "Approved" : "Not approved") },
            {
              label: "Creation Date",
              key: "created",
              transform: (val) => format(new Date(val), "dd. MMM yyyy 'kl.' HH.mm", { locale: daLocale }),
            },
          ]}
          data={reportState.reports}
          getData={getReportList}
          //onRowClick={getReport}
          textFilter
          refetch={refetch}
          loading={commonState.loading}
          count={commonState.reportCount}
          cellActionComponent={
            <ReportActionsMenu
              viewReport={getReport}
              assignReport={assignReport}
              removeReport={removeReport}
              downloadPdf={downloadPdf}
              generatePdf={generateNewPdf}
              role={user.role}
            />
          }
        />
      ) : (
        <Grid container item xs={12}>
          {!props.noBackButton && (
            <Grid item container>
              <Button
                onClick={() => {
                  setReportView(false);
                  reportActions.clearReport();
                }}
                startIcon={<ArrowBackIosIcon />}
              >
                Tilbage til rapportliste
              </Button>
            </Grid>
          )}
          <ChangeContext.Provider value={{ current: 0 }}>
            <ReportLayout
              sectionRefs={{}}
              changedTabIndexCallback={() => {
                /* */
              }}
              showTitle
              //view report from admin table in read only mode
              isReadOnly={true}
            />
          </ChangeContext.Provider>
        </Grid>
      )}
      <GeneralDialog
        open={showRemoveDialog}
        okButtonText="Ja"
        dialogTitle="Slet rapport"
        dialogText="Ønsker du at slette rapporten? Dette kan ikke fortrydes."
        handleClose={handleRemoveDialogClose}
      />
      <AssignDialog
        open={showAssignDialog}
        handleClose={handleAssignDialogClose}
        employees={
          user.company?.employees?.length
            ? user.company.employees
            : [
                {
                  firstName: user.firstName,
                  lastName: user.lastName,
                  id: user.id,
                  competencies: user.competencies,
                  role: CompanyRole.Manager,
                  email: user.email,
                  phone: user.phone,
                },
              ]
        }
      />
      <NotificationBar
        open={notification.notificationShown}
        variant={notification.notificationType}
        handleClose={handleNotificationClose}
        message={notification.notificationText}
      />
    </>
  );
};

export default Reports;

type AssignDialogProps = {
  open: boolean;
  handleClose: (dialogResult?: string) => void;
  employees: Employee[];
};

const AssignDialog = (props: AssignDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selected, setSelected] = useState<string>("");

  const handleChangeInspector = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    setSelected(event.target.value);
  };

  const changeInspector = () => {
    props.handleClose(selected);
  };

  return (
    <StyledDialog fullScreen={fullScreen} maxWidth="lg" open={props.open} onClose={() => props.handleClose()}>
      <DialogTitle align="center" variant="h5">
        Assign
      </DialogTitle>

      <StyledDialogContent>
        <DialogContentText variant="subtitle1">Assign an inspector to the report</DialogContentText>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select value={selected} onChange={handleChangeInspector}>
              {props.employees.map((x) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.firstName + " " + x.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <Typography className={classes.boldText}>
            {user.firstName} {user.lastName}
          </Typography> */}
        </Grid>
      </StyledDialogContent>

      <StyledDialogActions>
        <Button variant="outlined" onClick={() => props.handleClose()} color="primary">
          Annuller
        </Button>

        <Button variant="contained" onClick={changeInspector} color="primary">
          Ok
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};
