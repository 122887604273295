import React, { useState, useEffect, useReducer } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Typography, Grid, Divider, CircularProgress, LinearProgress, Autocomplete, Box, styled } from "@mui/material";
import { useUserActions } from "../user/userActions";
import { FormattedTextField, NumericTextField, PhoneField } from "../shared/FormattedFields";
import NotificationBar from "../shared/Notification";
import DawaService from "../services/AddressService";
import { CompanyRole, UpdateUserCommand, User } from "../interfaces/models";
import { PhoneRegexp } from "../constants/appConstants";
import { StateAction } from "../interfaces/frontend";
import { useUser } from "../common/hooks";
import { NIL } from "uuid";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ChangePasswordDialog from "../pages/dialogs/ChangePasswordDialog";
import { ControllerConnector } from "../shared/FormConnectors";
import { matchIsValidTel } from "mui-tel-input";

type UserFormData = {
  email: string;
  firstName: string;
  lastName: string;
  organization: string;
  cvr: string;
  phone: string;
  mobile: string;
  address: string;
  postCode: number;
  travelRange: number;
  pricePerHour: number;
  city: string;
};

interface UserProfileState extends User {
  id: string;
  error: string | undefined;
  updated: boolean;
  loading: boolean;
  open: boolean;
  municipalityOptions: [];
  selectedMunicipalities: [];
  updatingProfile: boolean;
  init?: undefined;
  showChangePasswordDialogOpened: boolean;
}

const initialState: UserProfileState = {
  userName: "",
  email: "",
  firstName: "",
  lastName: "",
  organization: "",
  cvr: "",
  phone: "",
  mobile: "",
  address: "",
  postCode: 0,
  city: "",
  role: 0,
  competencies: [],
  error: undefined,
  updated: false,
  loading: false,
  open: false,
  municipalCodes: [],
  municipalityOptions: [],
  selectedMunicipalities: [],
  updatingProfile: false,
  travelRange: 0,
  locked: false,
  pricePerHour: 0,
  id: NIL,
  created: new Date(),
  modified: new Date(),
  companyId: "",
  companyRole: CompanyRole.None,
  accessToken: "",
  lastLoginIp: "",
  webSite: "",
  lockoutEnabled: false,
  companyName: "",
  phoneNumber: "",
  showChangePasswordDialogOpened: false,
};

const reducer = (state: UserProfileState, action: StateAction<UserProfileState>) => {
  if (action.type === "init") {
    return { ...state, ...action.payload };
  }
  return { ...state, [action.type]: action.payload };
};

const UserProfile = () => {
  const user = useUser();
  const userActions = useUserActions();

  const methods = useForm<UserFormData>();

  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = methods;

  const [state, dispatch] = useReducer(reducer, initialState);

  const [notificationState, setNotificationState] = useState<{
    error: string | undefined;
    updated: boolean;
    pwdUpdated: boolean;
  }>({ error: undefined, updated: false, pwdUpdated: false });

  useEffect(() => {
    if (user) {
      dispatch({
        type: "init",
        payload: {
          ...initialState,
          id: user.id,
          role: user.role,
          competencies: user.competencies,
          municipalCodes: user.municipalCodes,
          companyId: user.companyId,
          companyRole: user.companyRole,
        },
      });
      reset({
        email: user.email || "",
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        organization: user.organization || "",
        cvr: user.cvr || "",
        phone: user.phone || "",
        mobile: user.mobile || "",
        address: user.address || "",
        postCode: user.postCode || 0,
        travelRange: user.travelRange || 0,
        pricePerHour: user.pricePerHour || 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.id) {
      DawaService.getDawaMunicipalities(state.municipalCodes).then((result) =>
        dispatch({ type: "selectedMunicipalities", payload: result })
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id]);

  const watchPostCode = watch("postCode");

  useEffect(() => {
    const getAsync = async () => {
      const city = await DawaService.getDawaCityByPostCode(watchPostCode);
      if (city) {
        setValue("city", city);
        clearErrors("postCode");
      } else {
        setError("postCode", { type: "manual", message: "Ugyldigt postnummer" });
        setValue("city", "");
      }
    };
    if (watchPostCode && /^[0-9]{4}$/.test(watchPostCode.toString())) {
      getAsync();
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPostCode]);

  const autoCompleteChange = async (event: React.SyntheticEvent<Element, Event>, value: string) => {
    if (value) {
      const values = await DawaService.getDawaMunicipality(value);
      if (values) {
        dispatch({ type: "municipalityOptions", payload: values });
      }
    }
  };

  const changeMunicipalities = (event: any, value: any) => {
    if (value) {
      const items: number = value.map((x: { kommune: { kode: string } }) => parseInt(x.kommune.kode));
      dispatch({ type: "selectedMunicipalities", payload: value });
      dispatch({ type: "municipalCodes", payload: items });
    }
  };

  const updateProfile = (data: UserFormData) => {
    //event.preventDefault();

    dispatch({ type: "updatingProfile", payload: true });

    const updateCmd: UpdateUserCommand = {
      id: state.id,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      companyId: state.companyId,
      companyName: data.organization,
      phone: data.phone,
      mobile: data.mobile,
      cvr: data.cvr,
      address: data.address,
      pricePerHour: data.pricePerHour,
      postCode: data.postCode,
      city: data.city,
      travelRange: data.travelRange,
      competencies: state.competencies,
      municipalCodes: state.municipalCodes,
    };

    userActions
      .updateProfile(updateCmd)
      .then(
        () => setNotificationState({ ...notificationState, updated: true }),
        (error) => showError(error.statusText)
      )
      .finally(() => dispatch({ type: "updatingProfile", payload: false }));
  };

  const showError = (error: string) => {
    setNotificationState({ ...notificationState, error });
  };

  const handleNotificationClose = () => {
    setNotificationState({
      error: undefined,
      updated: false,
      pwdUpdated: false,
    });
  };

  const closePasswordChangeDialog = (status: { error?: string; success: boolean }) => {
    if (!status.success) {
      if (status.error) {
        showError(status.error);
      }
    } else {
      setNotificationState({ ...notificationState, pwdUpdated: true });
    }
    dispatch({ type: "showChangePasswordDialogOpened", payload: false });
  };

  const handleFormError = (error: any) => {
    showError("Udfyld de markerede felter");
  };

  return (
    <>
      {state.updatingProfile ? <LinearProgress /> : <Box style={{ height: 4 }}></Box>}
      <Grid
        item
        container
        direction="column"
        sx={{
          minHeight: "calc(100vh - 68px)",
          marginBottom: "100px",
        }}
      >
        <Grid item xs={8} md={4} p={2}>
          <Typography sx={{ marginLeft: "10px", fontWeight: "bold", marginBottom: "5px" }}>PROFIL</Typography>
          <Divider />
        </Grid>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(updateProfile)} onError={handleFormError}>
            <Grid item container xs={12} sx={{ flex: 1, justifyContent: "center", mt: 1 }}>
              {user.companyRole === CompanyRole.Manager && (
                <Grid container item xs={12} sm={5.5} direction={"column"} mx={1}>
                  <Typography variant="button">Virksomhed</Typography>
                  <Grid item>
                    <Controller
                      name="cvr"
                      control={control}
                      rules={{ required: true, pattern: /^[0-9]{8}$/ }}
                      render={({ field }) => (
                        <FormattedTextField
                          format="########"
                          variant="outlined"
                          fullWidth
                          autoComplete="off"
                          margin="dense"
                          id="cvr"
                          label="CVR"
                          error={!!errors.cvr}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      name="organization"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          autoComplete="off"
                          variant="outlined"
                          margin="dense"
                          id="organization"
                          label="Firma"
                          InputLabelProps={{ shrink: true }}
                          error={!!errors.organization}
                          {...field}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item>
                    <Controller
                      name="address"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          autoComplete="off"
                          variant="outlined"
                          margin="dense"
                          id="address"
                          label="Adresse"
                          type="text"
                          error={!!errors.address}
                          {...field}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs={4}>
                      <Controller
                        name="postCode"
                        control={control}
                        rules={{ required: true, pattern: /^[0-9]{4}$/ }}
                        render={({ field }) => (
                          <FormattedTextField
                            format="####"
                            variant="outlined"
                            autoComplete="off"
                            margin="dense"
                            id="postCode"
                            label="Postnr."
                            error={!!errors.postCode}
                            helperText={errors.postCode?.message}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ReadOnlyTextField
                        autoComplete="off"
                        margin="dense"
                        fullWidth
                        id="city"
                        label="By"
                        type="text"
                        {...register("city")}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <ControllerConnector
                      name="phone"
                      rules={{
                        pattern: PhoneRegexp,
                        maxLength: 16,
                        validate: (e: any) => {
                          if (e === "") return true;
                          return matchIsValidTel(e);
                        },
                      }}
                    >
                      <PhoneField
                        variant="outlined"
                        fullWidth
                        label="Telefon"
                        error={!!errors.phone}
                        type="phone"
                      />
                    </ControllerConnector>
                  </Grid>
                  <Grid item>
                    <Controller
                      name="pricePerHour"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <NumericTextField
                          suffix=" Kr"
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fullWidth
                          autoComplete="off"
                          variant="outlined"
                          margin="dense"
                          label="Gennemsnitlig timepris ekskl. moms"
                          error={!!errors.pricePerHour}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      name="travelRange"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <NumericTextField
                          suffix=" Km"
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={0}
                          fullWidth
                          autoComplete="off"
                          variant="outlined"
                          margin="dense"
                          label="Arbejdsradius "
                          error={!!errors.travelRange}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container item xs={12} sm={5.5} direction={"column"} mx={1}>
                <Typography variant="button">Bruger</Typography>
                <Grid item>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="firstName"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        margin="dense"
                        id="firstName"
                        label="Fornavn"
                        error={!!errors.firstName}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="lastName"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        margin="dense"
                        id="lastName"
                        label="Efternavn"
                        error={!!errors.lastName}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="email"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        autoComplete="off"
                        variant="outlined"
                        margin="dense"
                        id="email"
                        label="Email"
                        error={!!errors.email}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item>
                  <ControllerConnector
                    name="mobile"
                    rules={{
                      pattern: PhoneRegexp,
                      maxLength: 16,
                      validate: (e: any) => {
                        if (e === "") return true;
                        return matchIsValidTel(e);
                      },
                    }}
                  >
                    <PhoneField
                      variant="outlined"
                      fullWidth
                      label="Mobil"
                      error={!!errors.mobile}
                      type="phone"
                      autoComplete="off"
                      margin="dense"
                    />
                  </ControllerConnector>
                </Grid>
                <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    sx={{
                      margin: 1,
                      width: 200,
                      maxHeight: 60,
                      mr: 0,
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => dispatch({ type: "showChangePasswordDialogOpened", payload: true })}
                  >
                    Skift kodeord
                  </Button>
                </Grid>
              </Grid>

              {/* Need to determine if there are more extended users in the future*/}
              {user.companyRole === CompanyRole.Manager && (
                <Grid container justifyContent={"center"}>
                  <Grid item xs={11}>
                    <Autocomplete
                      multiple
                      open={state.open}
                      value={state.selectedMunicipalities}
                      onChange={changeMunicipalities}
                      defaultValue={state.selectedMunicipalities}
                      onInputChange={autoCompleteChange}
                      onOpen={() => {
                        dispatch({ type: "open", payload: true });
                      }}
                      onClose={() => {
                        dispatch({ type: "open", payload: false });
                      }}
                      noOptionsText="Ingen muligheder"
                      getOptionLabel={(option) => parseInt(option.kommune.kode) + " " + option.kommune.navn}
                      options={state.municipalityOptions}
                      loading={state.loading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Kommuner"
                          margin="dense"
                          fullWidth
                          autoComplete="off"
                          type="text"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                            autoComplete: "off",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={11} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  sx={{
                    margin: 1,
                    width: 200,
                    marginRight: 0,
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={state.updatingProfile}
                >
                  Gem profil
                </Button>
              </Grid>
              {/* </Grid> */}
            </Grid>
          </form>
        </FormProvider>
      </Grid>
      <ChangePasswordDialog open={state.showChangePasswordDialogOpened} handleClose={closePasswordChangeDialog} />
      <NotificationBar
        open={!!notificationState.error}
        variant="error"
        handleClose={handleNotificationClose}
        message={notificationState.error}
      />
      <NotificationBar
        open={!!notificationState.pwdUpdated}
        variant="success"
        handleClose={handleNotificationClose}
        message={"Kodeordet skiftet"}
      />
      <NotificationBar
        open={notificationState.updated}
        variant="success"
        handleClose={handleNotificationClose}
        message={"Profilen er gemt"}
      />
    </>
  );
};

export default UserProfile;

export const ReadOnlyTextField = styled(TextField)(() => ({
  ".MuiInputBase-input": {
    textAlign: "center",
  },
  ".MuiInputBase-root.Mui-disabled": {
    opacity: 1,
    "input.Mui-disabled": {
      color: "#000",
      WebkitTextFillColor: "#000",
    },
  },
}));
