import React, { useReducer, useEffect, useContext } from "react";
import { Grid, TextField, Divider, styled, Button } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { makeStyles } from "@mui/styles";
import { EmailRegExp } from "../../constants/appConstants";
import daLocale from "date-fns/locale/da";
import { FeatureMetadata, PictureRef } from "../../interfaces/models";
import { PhoneField } from "../../shared/FormattedFields";
import ChangePictureButton from "../../shared/ChangePictureButton";
import ImageModal from "../../shared/ImageModal";
import Picture from "../../shared/Picture";
import { ChangeContext } from "../CreateReportPage";
import { NIL } from "uuid";
import { ContentCopy } from "@mui/icons-material";
import NotificationBar from "../../shared/Notification";
import { matchIsValidTel } from "mui-tel-input";
import { ErrorStyledTooltip } from "../../shared/Tooltips";
import { useReport } from "../reportActions";

const InputContainer = styled(Grid)`
  justify-content: flex-start;
  align-items: center;
`;

const ReportInput = styled(TextField)`
  min-width: 300px;
  & input {
    color: black;
  }
`;

const useStyles = makeStyles({
  rootContainer: {
    marginTop: 20,
  },
  pictureWrapper: {
    maxWidth: 300,
  },
  marginTop: {
    marginTop: 60,
  },
});

interface ReportHeaderState extends FeatureMetadata {
  reportId: string;
  picShown: boolean;
  linkCopied: boolean;
}

interface ReportHeaderProps extends FeatureMetadata {
  reportId: string;
  allowUpdate: boolean;
  customerLink?: string;
  changeReportInfo: (name: string, value: string) => void;
  updateHousePic?: (image: PictureRef) => void;
}

const initialState: ReportHeaderState = {
  reportId: NIL,
  owner: "",
  ownerLastName: "",
  ownerPhone: "",
  ownerEmail: "",
  municipality: "",
  cadastral: "",
  caseNumber: 0,
  reportDate: new Date(),
  houseId: NIL,
  postcode: 0,
  latitude: 0,
  longitude: 0,
  picShown: false,
  area: 0,
  livingArea: 0,
  unitArea: 0,
  groundArea: 0,
  basementArea: 0,
  buildYear: 0,
  rooms: 0,
  address: "",
  city: "",
  propertyType: "",
  unitType: "",
  energyLabel: "",
  picture: "",
  linkCopied: false,
};

const reducer = (state: typeof initialState, action: { type: keyof typeof initialState | "reset"; payload: any }) => {
  switch (action.type) {
    case "reset":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

const ReportHeader = (props: ReportHeaderProps) => {
  const classes = useStyles();

  const changeCtx = useContext(ChangeContext);

  const [state, dispatch] = useReducer(reducer, { ...initialState });

  const handleDateChange = (value: any) => {
    dispatch({ type: "reportDate", payload: value });
    props.changeReportInfo("reportDate", value);
  };

  const changeLocalValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeCtx.current++;
    const { name, value } = event.target;
    dispatch({ type: name as keyof ReportHeaderState, payload: value });
    props.changeReportInfo(name, value);
  };

  const handlePhoneChange = (value: string) => {
    changeLocalValue({
      target: {
        name: "ownerPhone",
        value,
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const updateHousePic = (image: PictureRef) => {
    changeCtx.current++;
    if (props.updateHousePic) {
      props.updateHousePic(image);
    }
  };

  const closePic = () => {
    dispatch({ type: "picShown", payload: false });
  };

  useEffect(() => {
    dispatch({ type: "owner", payload: props.owner });
    dispatch({ type: "ownerLastName", payload: props.ownerLastName });
    dispatch({ type: "ownerPhone", payload: props.ownerPhone || "" });
    dispatch({ type: "ownerEmail", payload: props.ownerEmail });
    if (props.reportDate) {
      dispatch({ type: "reportDate", payload: new Date(props.reportDate) });
    }
    return () => {
      //
    };
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportId, props.owner, props.ownerLastName, props.ownerPhone, props.ownerEmail]);

  // const changeValue = debounce((name: string, value: string) => {
  //   props.changeReportInfo(name, value);
  // }, 100);

  const [report] = useReport();

  return (
    <>
      <Grid container justifyContent="center" className={classes.rootContainer}>
        <Grid container>
          <Grid item xs={12} sm={6} container spacing={2}>
            <ErrorStyledTooltip title={report.approved ? "Godkendt rapport kan ikke ændres" : ""}>
              <InputContainer item xs={12}>
                <ReportInput
                  error={!state.owner}
                  name="owner"
                  label="Fornavn"
                  value={state.owner || ""}
                  onChange={changeLocalValue}
                  disabled={!props.allowUpdate}
                />
              </InputContainer>
            </ErrorStyledTooltip>
            <ErrorStyledTooltip title={report.approved ? "Godkendt rapport kan ikke ændres" : ""}>
              <InputContainer item xs={12}>
                <ReportInput
                  error={!state.ownerLastName}
                  name="ownerLastName"
                  label="Efternavn"
                  value={state.ownerLastName || ""}
                  onChange={changeLocalValue}
                  disabled={!props.allowUpdate}
                />
              </InputContainer>
            </ErrorStyledTooltip>
            <ErrorStyledTooltip title={report.approved ? "Godkendt rapport kan ikke ændres" : ""}>
              <InputContainer item xs={12}>
                <PhoneField
                  id="ownerPhone"
                  name="ownerPhone"
                  inputProps={{ maxLength: 15 }} // 15 is the max length of a phone number including whitespace and country code
                  sx={{ width: "300px" }}
                  fullWidth
                  label="Telefon"
                  type="phone"
                  error={!matchIsValidTel(state.ownerPhone) && props.allowUpdate}
                  value={state.ownerPhone || ""}
                  onChange={(e) => handlePhoneChange(e.toString())}
                  disabled={!props.allowUpdate}
                />
              </InputContainer>
            </ErrorStyledTooltip>
            <ErrorStyledTooltip title={report.approved ? "Godkendt rapport kan ikke ændres" : ""}>
              <InputContainer item xs={12}>
                <ReportInput
                  autoComplete="new-password"
                  error={!EmailRegExp.test(state.ownerEmail)}
                  name="ownerEmail"
                  label="Email"
                  value={state.ownerEmail || ""}
                  onChange={changeLocalValue}
                  disabled={!props.allowUpdate}
                  type="email"
                />
              </InputContainer>
            </ErrorStyledTooltip>
            <InputContainer item xs={12}>
              <ReportInput label="Adresse" value={props.address || ""} disabled />
            </InputContainer>
            <InputContainer item xs={12}>
              <ReportInput label="Postnr." value={props.postcode || ""} disabled />
            </InputContainer>
            <InputContainer item xs={12}>
              <ReportInput label="By" value={props.city || ""} disabled />
            </InputContainer>
            <InputContainer item xs={12}>
              <ReportInput
                name="municipality"
                label="Kommunenr./Ejendomsnr."
                value={props.municipality || ""}
                onChange={changeLocalValue}
                disabled
              />
            </InputContainer>
            <InputContainer item xs={12} mt={8}>
              <ReportInput
                name="cadastral"
                label="Matrikel/Ejerlav"
                value={props.cadastral || ""}
                onChange={changeLocalValue}
                disabled
              />
            </InputContainer>
            <InputContainer item xs={12}>
              {!!props.caseNumber && props.caseNumber !== 0 && (
                <ReportInput disabled name="caseNumber" label="Internt sagsnummer" value={props.caseNumber || ""} />
              )}
            </InputContainer>
            <InputContainer item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={daLocale}>
                <DatePicker
                  label="Dato for besigtigelse"
                  value={state.reportDate}
                  onChange={(value) => handleDateChange(value)}
                  disabled={!props.allowUpdate}
                  // slots={{ field: DateField }}
                  sx={{ minWidth: 300 }}
                  // slotProps={{
                  //   field: { id: "report-date" },
                  // }}
                />
              </LocalizationProvider>
            </InputContainer>
            {/* <InputContainer item xs={12} md={6}></InputContainer> */}
          </Grid>
          {/* picture */}
          <InputContainer
            item
            xs={12}
            sm={6}
            md={6}
            spacing={1}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {props.picture && (
              <>
                <Grid item container justifyContent="center" className={classes.pictureWrapper}>
                  <Picture id={"house-image"} {...props} mainPicture source={props.picture} width={300} />
                </Grid>
                {!!props.updateHousePic && props.allowUpdate && (
                  <Grid item>
                    <ChangePictureButton
                      buttonText="Skift billede"
                      noPicture={!props.picture}
                      getPicture={updateHousePic}
                    />
                  </Grid>
                )}
              </>
            )}
            {props.customerLink && (
              <Grid item>
                {/* <ReportInput
                  label="Customer link"
                  value={window.location.origin+"/s/"+props.customerLink}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          if (navigator.clipboard && props.customerLink) {
                            navigator.clipboard.writeText(window.location.origin+"/s/"+props.customerLink);
                          }
                        }}
                      >
                        <ContentCopy />
                      </IconButton>
                    ),
                  }}
                /> */}
                <Button
                  startIcon={<ContentCopy />}
                  variant="contained"
                  color="primary"
                  disabled={!navigator.clipboard}
                  onClick={async () => {
                    if (navigator.clipboard && props.customerLink) {
                      try {
                        await navigator.clipboard.writeText(window.location.origin + "/s/" + props.customerLink);
                        dispatch({ type: "linkCopied", payload: true });
                      } catch (e) {
                        console.log(e);
                      }
                    }
                  }}
                >
                  Kopier link til "forbedringer" til udklipsholder
                </Button>
              </Grid>
            )}
          </InputContainer>
          {/* picture */}

          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 2,
              paddingBottom: 3,
            }}
          >
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <ImageModal shown={state.picShown} handleClose={closePic} source={props.picture} />
      <NotificationBar
        open={state.linkCopied}
        variant={"success"}
        handleClose={() => dispatch({ type: "linkCopied", payload: false })}
        message={"Link kopieret til udklipsholder"}
      />
    </>
  );
};

export default React.memo(ReportHeader);
