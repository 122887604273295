import React from "react";
import { View, Text } from "@react-pdf/renderer";
import styles from "../pdfDocumentStyles";
import { FeatureMetadata } from "../../../../../interfaces/models";

const PdfDocInside = (props: FeatureMetadata & { firstName: string; lastName: string; }) => {
  return (
    <View style={styles.column}>
      <Text style={{ ...styles.pageTextTitleBold }}>Indedning</Text>

      <View>
        <Text style={{ ...styles.pageTextLastLine }}>
          Byggesagkyndig.nu har i dag foretaget en visuel besigtigelse samt gennemgang af udvalgte bygningsdele på
          ejendom beliggende:{props.address}, {props.postcode} {props.city}
        </Text>
        <Text style={{ ...styles.pageTextLastLine }}>
          {"Besigtigelsen samt tilstandsvurdering er udført af byggesagkyndig: " +
            props.firstName +
            " " +
            props.lastName}
        </Text>
        <Text style={{ ...styles.pageTextLastLine }}>
          Besigtigelse samt tilstandsvurdering er udført efter efter aftale med: {props.owner} {props.ownerLastName}
        </Text>
        <Text style={{ ...styles.pageTextLastLine, ...styles.borderTop }}>
          Formålet med denne rapport er jf. fremsendte ordrebekræftelse, at foretage en uvildig undersøgelse af
          ejendommens vedligeholdelsesstand, samt vurdere i hvilket omfang evt. skader eller vedligeholdelsesarbejder
          bør prioriteres økonomisk og tidsmæssigt i de kommende 5 år fra besigtigelsen.
        </Text>
      </View>
    </View>
  );
};

export default PdfDocInside;
