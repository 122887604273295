import { Report, Common, Admin, Template } from "../constants/actionTypes";
import {
  combineReportTree,
  flattenReportTree,
  getLocalData,
  updateLocalData,
  removeLocalData,
  calculateEvaluation,
} from "../common/utils";
import { useCommonActions, handleError } from "../common/commonActions";
import { LocalStorageItems } from "../constants/appConstants";
import TemplateService from "../services/TemplateService";
import ReportService from "../services/ReportService";
import DawaService from "../services/AddressService";
import {
  FeatureMetadata,
  InspectorReportObject,
  TemplateItem,
  TemplateModel,
  PictureRef,
  InspectorReportParameter,
  InspectorReport,
  TimeToFinish,
  ParameterDescription,
  AddressFilter,
  CustomerReportWishList,
  WishListParameter,
  TransferReportToDeviceCommand,
} from "../interfaces/models";
import { AppError, MaintenanceInfo, MaintenanceInfoSection } from "../interfaces/frontend";
import { AppState } from "../store/store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { initReportState, ReportState } from "./reportReducer";
import { cloneDeep } from "lodash";
import { NIL, v4 as uuidv4 } from "uuid";
import ApiService from "../services/ApiService";
import { useAppDispatch } from "../common/hooks";
import { useSelector } from "react-redux";
import _ from "lodash";
import { CommonState } from "../common/commonReducer";

export type ReportStateProps = ReturnType<typeof useReportState>;

export const useReportState = () => {
  const reportState = useSelector((state: AppState) => state.report);
  const reports = useSelector((state: AppState) => state.common.reportList);
  const commonLoading = useSelector((state: AppState) => state.common.loading);
  return {
    ...reportState,
    reports,
    commonLoading,
  };
};

export type ReportActions = ReturnType<typeof useReportActions>;

export const useReportActions = () => {
  const dispatch = useAppDispatch();
  const commonActions = useCommonActions();

  return {
    getGroupedReportList: (filter: string, skip: number, take: number) =>
      commonActions.getGroupedReportList(filter, skip, take),
    getLocalReport: () => dispatch(getLocalReport()),
    getDefaultTemplate: () => dispatch(getDefaultTemplate()),
    getReportList: (
      filter: any,
      skip: number,
      take: number,
      noHistory: boolean,
      addressFilter: AddressFilter,
      allStates?: boolean,
      personalOnly?: boolean
    ) => commonActions.getReportList(filter, skip, take, noHistory, addressFilter, allStates, personalOnly),
    getReport: (id: string) => dispatch(getReport(id)),
    uploadReport: () => dispatch(uploadReport()),
    clearReport: (template?: TemplateModel, saveLocalStorage?: boolean) =>
      dispatch(clearReport(template, saveLocalStorage)),
    changeReportInfo: (key: string, value: any) => dispatch(changeReportInfo(key, value)),
    setReportData: (data: FeatureMetadata, updateCurrent?: boolean) => dispatch(setReportData(data, updateCurrent)),
    addReportObject: (data: InspectorReportObject) => dispatch(addReportObject(data)),
    updateObjects: (data: InspectorReportObject[]) => dispatch(updateObjects(data)),
    updateHousePic: (imageData: PictureRef) => dispatch(updateHousePic(imageData)),
    openPdfView: () => commonActions.openPdfView(),
    closePdfView: () => commonActions.closePdfView(),
    approveReport: () => dispatch(approveReport()),
    unapproveReport: () => dispatch(unapproveReport()),
    updateEmptyValues: () => dispatch(updateEmptyValues()),
    // applyFilter: (value: string) => commonActions.applyFilter(value),
    changeValue: (itemId: string, key: string, value: any) => dispatch(changeValue(itemId, key, value)),
    addPicture: (pic: PictureRef) => dispatch(addPicture(pic)),
    removePicture: (pic: PictureRef) => dispatch(removePicture(pic)),
    changeObject: (id: string, key: string, value: string) => dispatch(changeObject(id, key, value)),
    addNewTemplateItem: (objectId: string, item: TemplateItem) => dispatch(addNewTemplateItem(objectId, item)),
    toggleHiddenSection: (objectId: string, sectionId: string) => dispatch(toggleHiddenSection(objectId, sectionId)),
    addNewParameter: (item: InspectorReportParameter) => dispatch(addNewParameter(item)),
    removeParameter: (itemId: string) => dispatch(removeParameter(itemId)),
    saveDoc: (reportId: string, pdf: File) => dispatch(saveDoc(reportId, pdf)),
    updateObjectTemplate: (objectId: string, template: TemplateModel) =>
      dispatch(updateObjectTemplate(objectId, template)),
    updateTemplateItem: (objectId: string, item: TemplateItem) => dispatch(updateTemplateItem(objectId, item)),
    updateMaintenanceInfo: () => dispatch(updateMaintenanceInfo()),
    addDescription: (itemId: string, desc: ParameterDescription) => dispatch(addDescription(itemId, desc)),
    removeDescription: (itemId: string, descriptionId: string) => dispatch(removeDescription(itemId, descriptionId)),
    changeDescription: (itemId: string, descriptionId: string, value: string) =>
      dispatch(changeDescription(itemId, descriptionId, value)),
    addDescriptionPicture: (itemId: string, descId: string, pic: PictureRef) =>
      dispatch(addDescriptionPicture(itemId, descId, pic)),
    hideGroupValues: (groupId: string, exceptId: string) => dispatch(hideGroupValues(groupId, exceptId)),
    toggleAutoSave: () => dispatch(toggleAutoSave()),
    // synchronizeReport: () => dispatch(synchronizeReport()),
    transferReport: (employeeId: string) => dispatch(transferReport(employeeId)),
    addToWishList: (param: { id: string; label: string; priceEstimate: number }) => dispatch(addToWishList(param)),
    removeFromWishList: (id: string) => dispatch(removeFromWishList(id)),
    evaluateSection: (objectId: string, sectionId: string, value: number) =>
      dispatch(evaluateSection(objectId, sectionId, value)),
    updateEvaluation: () => dispatch(updateEvaluation()),
    setCollapseAll: (value: boolean) => dispatch(setCollapseAll(value)),
    assignReport: (reportId: string, employeeId: string) => dispatch(assignReport(reportId, employeeId)),
    removeReport: (reportId: string) => dispatch(removeReport(reportId)),
    clearReportList: () => commonActions.clearReportList(),
    enableEditing: (request: TransferReportToDeviceCommand) => dispatch(enableEditing(request)),
    closeReport: (reportId: string) => dispatch(closeReport(reportId)),
  };
};

export const useReport = (): [ReportStateProps, ReportActions] => {
  const state = useReportState();
  const actions = useReportActions();
  return [state, actions];
};

export type CustomerReportActions = ReturnType<typeof useCustomerReportActions>;

export const useCustomerReportActions = () => {
  const commonActions = useCommonActions();
  const dispatch = useAppDispatch();
  return {
    getCustomerReportList: (filter: any, skip: number, take: number) =>
      dispatch(getCustomerReportList(filter, skip, take)),
    getCustomerReport: (id: string): Promise<InspectorReport | undefined> => dispatch(getCustomerReport(id)),
    switchPdf: () => commonActions.openPdfView(),
    // applyFilter: (value: string) => commonActions.applyFilter(value),
  };
};

const getDefaultTemplate = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Report.GET_REPORT_TEMPLATE });
  try {
    const template = await TemplateService.getDefaultTemplate();
    dispatch({
      type: Report.GET_REPORT_TEMPLATE_SUCCEEDED,
      payload: template,
    });
    return template;
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, {
      type: Template.TEMPLATE_REQUEST_FAILED,
      payload: error,
      error: true,
    });
  }
};

const getLocalReport = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  const localData = await getLocalData<InspectorReport>(LocalStorageItems.Report);
  if (localData) {
    dispatch({ type: Report.GET_REPORT_SUCCEEDED, payload: localData });
    const commonState = await getLocalData<CommonState>(LocalStorageItems.Common);
    if (commonState?.pdfView) {
      dispatch({ type: Common.SWITCH_PDF_MODE, payload: commonState?.pdfView });
    }
    return localData;
  }
};

const uploadReport = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
  const state = getState().report;
  const deviceId = getState().common.deviceId;
  if (state.approved) {
    handleError(
      {
        title: "Du kan ikke gemme en rapport som er godkendt!",
        message:
          "Kontakt admin hvis du skal have åbnet en rapport. Dine rettelser siden godkendelsen vil gå tabt, når du loader rapport igen, så du kan eventuelt tage screen shots for at huske dine rettelser.",
      },
      dispatch
    );
    throw new Error("Du kan ikke gemme en rapport som er godkendt!\nKontakt admin hvis du skal have åbnet en rapport.");
  }

  if (!state.parameters.length) {
    handleError("Rapporten er tom", dispatch);
    throw new Error("Rapporten er tom");
  }
  dispatch({ type: Report.UPLOAD_REPORT });

  //await dispatch(applyLocalChanges());

  //do not upload new pictures at this step
  const report = combineReportTree({
    ...state,
    pictures: state.pictures.filter((x) => !x.data),
    parameters: state.parameters.map((x) => {
      return {
        ...x,
        descriptions: x.descriptions.map((d) => {
          return {
            ...d,
            pictures: d.pictures?.filter((p) => !p.data),
          };
        }),
      };
    }),
  });

  // const hubConnection = common.hubConnection;

  let reportId = NIL;

  try {
    // if (report.id !== NIL) {
    //   const canUpdate = await hubConnection?.invoke("Lock", report.id, common.deviceId);
    //   if (!canUpdate) {
    //     await dispatch(attachToSync());
    //   }
    // }

    reportId = await ReportService.uploadReport({
      ...report,
      deviceId,
    });

    // if (reportId !== report.id) {
    //   await dispatch(attachToSync());
    // }
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, {
      type: Report.UPLOAD_REPORT_FAILED,
      payload: error,
      error: true,
    });
    throw error;
  }

  if (reportId === NIL) {
    const error = { payload: "No report id" };
    throw error;
  }

  //Check if report has  pictures which  where not been uploaded
  const pics = state.pictures.filter((x) => x.data);
  const descPict = state.parameters
    .flatMap((x) => x.descriptions)
    .flatMap((x) => x.pictures)
    .filter((x) => x?.data);

  //upload pictures if they where not been uploaded
  if (pics.length > 0 || descPict.length > 0) {
    dispatch({ type: Report.UPLOAD_PICTURES });

    if (pics.length > 0) {
      for (let i = 0; i < pics.length; i++) {
        try {
          pics[i].featureId = state.id;
          await ReportService.uploadPicture({
            id: pics[i].id,
            featureId: state.id,
            data: pics[i].data || "",
            objectId: pics[i].objectId,
            deviceId,
          });
          dispatch({ type: Report.UPLOAD_PICTURE_SUCCEEDED });
          dispatch({
            type: Report.UPLOAD_PICTURES_PROGRESS,
            payload: Math.round(((i + 1) / pics.length) * 100),
          });
        } catch (error: unknown) {
          handleError(error as string | AppError, dispatch);
          throw error;
        }
      }
    }

    if (descPict.length > 0) {
      for (let i = 0; i < state.parameters.length; i++) {
        const param = state.parameters[i];
        for (let j = 0; j < param.descriptions.length; j++) {
          const desc = param.descriptions[j];
          if (desc.pictures) {
            for (let k = 0; k < desc.pictures.length; k++) {
              if (desc.pictures[k].data) {
                try {
                  await ReportService.uploadDescriptionPicture({
                    id: desc.pictures[k].id,
                    featureId: state.id,
                    objectId: desc.pictures[k].objectId,
                    itemId: param.id,
                    descriptionId: desc.id,
                    data: desc.pictures[k].data || "",
                    deviceId,
                  });
                } catch (error: unknown) {
                  handleError(error as string | AppError, dispatch);
                  throw error;
                }
              }
            }
          }
        }
      }
    }

    dispatch({ type: Report.UPLOAD_PICTURES_FINISHED });
  }

  dispatch({ type: Report.UPLOAD_REPORT_SUCCEEDED });
  dispatch({ type: Report.NEED_SAVE_REPORT, payload: false });
  dispatch(updateEvaluation());
  await dispatch(applyLocalChanges());
  // await hubConnection?.invoke("Unlock", report.id, common.deviceId);
  return reportId;
};

const getReport =
  (id: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    dispatch({ type: Report.GET_REPORT });
    let isMerged = false;
    try {
      const deviceId = getState().common.deviceId;
      const report = await ReportService.getReport(id, deviceId);
      if (!report) {
        throw new Error("Rapport ikke fundet");
      }

      let currentReport = getState().report;
      let currentReportId = currentReport.id;
      if (currentReportId === NIL) {
        const localCurrentReport = await getLocalData(LocalStorageItems.Report);

        //if report from state is empty, try to get it from local storage
        if (localCurrentReport && (localCurrentReport as ReportState).id !== NIL) {
          currentReportId = (localCurrentReport as ReportState).id;
          currentReport = localCurrentReport as ReportState;
        }
      }

      // if (currentReportId !== NIL && currentReportId !== id) {
      //   const common = getState().common;
      //   const hubConnection = common.hubConnection;
      //   await hubConnection?.invoke("StopSync", currentReportId, common.deviceId);
      // }

      let state = flattenReportTree(report);
      //if report in state not empty, merge data from it into loaded report.
      //It helps fix lose of new changes in report if they has been done while upload report
      const needToMerge = isNeedToSave(state, currentReport);

      if (currentReportId !== NIL && currentReportId === id && needToMerge) {
        state = mergeReportState(currentReport, state);
        isMerged = true;
      }

      await dispatch({ type: Report.GET_REPORT_SUCCEEDED, payload: state });
      //await dispatch(attachToSync());

      //TODO: reconsider how to use a local database for storing more than one report and not clear everything while updating
      await removeLocalData(LocalStorageItems.Report);
      await removeLocalData(LocalStorageItems.Common);

      const localReport = getState().report;
      localReport.isMerged = isMerged;

      if (!localReport.template || (localReport.template && localReport.template.id !== report.template.id)) {
        dispatch({ type: Report.GET_REPORT_TEMPLATE });
        try {
          const template = await TemplateService.getTemplate(report.template.id);
          dispatch({
            type: Report.GET_REPORT_TEMPLATE_SUCCEEDED,
            payload: template,
          });
        } catch (error: unknown) {
          handleError(error as string | AppError, dispatch, {
            type: Report.GET_REPORT_TEMPLATE_FAILED,
            payload: error,
          });
        }
      }
      await updateLocalData(LocalStorageItems.Report, localReport);

      dispatch({ type: Report.MERGED_REPORT, payload: isMerged });
      dispatch({ type: Report.NEED_SAVE_REPORT, payload: isMerged });
      return localReport;
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch, {
        type: Report.GET_REPORT_FAILED,
        payload: error,
      });
    }
  };

const clearReport =
  (template?: TemplateModel, saveLocalStorage?: boolean) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    if (!saveLocalStorage) {
      await removeLocalData(LocalStorageItems.Report);
    }
    dispatch({ type: Common.SWITCH_PDF_MODE, payload: false });
    await removeLocalData(LocalStorageItems.Common);
    const report = initReportState(template);
    dispatch({ type: Report.INIT_REPORT, payload: report });
  };

const changeReportInfo = (key: string, value: any) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({
    type: Report.UPDATE_REPORT_DATA,
    payload: { key, value },
  });
};

const changeValue =
  (itemId: string, key: string, value: any) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({
      type: Report.CHANGE_REPORT_VALUE,
      payload: { itemId, key, value },
      //payload: { itemId, objectId, key, value },
    });
  };

const updateObjects =
  (values: InspectorReportObject[]) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const report = getState().report;
    let params: InspectorReportParameter[] = [];

    const newObjects = report.objects?.length
      ? report.objects.filter((x) => values.findIndex((y) => x.id === y.id) !== -1)
      : [];

    const removed = report.objects?.filter((x) => values.findIndex((y) => x.id === y.id) === -1);
    if (removed?.length) {
      params = report.parameters.filter((x) => removed?.some((y) => x.objectId !== y.id));
      if (params.length) {
        dispatch({
          type: Report.UPDATE_REPORT_PARAMETERS,
          payload: params,
        });
      }
    }

    for (let i = 0; i < newObjects.length; i++) {
      const item = newObjects[i];
      const updated = values.filter((i) => i.id === item.id)[0];
      if (item.name !== updated.name) {
        item.name = updated.name;
      }
      item.label = getLabel(i);
    }

    dispatch({
      type: Report.UPDATE_REPORT_OBJECTS,
      payload: newObjects,
    });
  };

const setReportData =
  (data: FeatureMetadata, updateCurrent?: boolean) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    let newData = await DawaService.getHouseData(data);
    try {
      const mhData = await ReportService.getHouseInfo(data.houseId);
      newData = {
        ...newData,
        propertyType: mhData.PropertyType,
        unitType: mhData.UnitData.UnitType,
        area: mhData.UnitData.Area,
        livingArea: mhData.UnitData.LivingArea,
        unitArea: mhData.UnitData.UnitArea,
        rooms: mhData.UnitData.Rooms,
        basementArea: mhData.UnitData.BasementArea,
        buildYear: mhData.UnitData.BuildYear,
        energyLabel: mhData.UnitData.EnergyLabel,
        groundArea: mhData.UnitData.GroundArea,
      };
    } catch (error: unknown) {
      //do not throw; the service can be not available
    }

    if (!updateCurrent) {
      const template = { ...getState().report.template };
      dispatch({
        type: Report.INIT_REPORT,
        payload: initReportState(template),
      });
    }
    dispatch({
      type: Report.SET_REPORT_DATA,
      payload: newData,
    });
  };

const approveReport = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
  const report = getState().report;

  if (report) {
    dispatch({ type: Report.APPROVE_REPORT });
    try {
      if (report.needSave) {
        await dispatch(uploadReport());
      }
      // const common = getState().common;
      // const hubConnection = common.hubConnection;
      // const canUpdate = await hubConnection?.invoke("Lock", report.id, common.deviceId);
      // if (!canUpdate) {
      //   await dispatch(attachToSync());
      // }

      await ReportService.approveReport(report.id);

      // await hubConnection?.invoke("Unlock", report.id, common.deviceId);

      dispatch({
        type: Report.APPROVE_REPORT_SUCCEEDED,
      });
      await updateLocalData(LocalStorageItems.Report, getState().report);
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch, {
        type: Report.APPROVE_REPORT_FAILED,
        payload: error,
      });
    }
  }
};

const unapproveReport = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
  const report = getState().report;
  if (report) {
    dispatch({ type: Admin.UNAPPROVE_REPORT });
    try {
      await ReportService.unapproveReport(report.id);
      dispatch({
        type: Admin.UNAPPROVE_REPORT_SUCCEEDED,
      });
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch, {
        type: Admin.UNAPPROVE_REPORT_FAILED,
        payload: error,
      });
    }
  }
};

const updateEmptyValues = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
  const state = getState().report;
  const report = { ...state };
  if (!report.objects?.length) return;

  const parameters = cloneDeep(state.parameters);

  if (report.template) {
    const emptyValue = report.template?.options?.find((x) => !x.worth);
    if (!emptyValue) return;

    parameters.forEach((x) => {
      if (!x.value) x.value = emptyValue.value;
    });

    dispatch({
      type: Report.UPDATE_REPORT_EMPTY_VALUES,
      payload: parameters,
    });
  }
};

const getLabel = (position: number) => {
  let label = "A";
  const num = Math.floor(position / 26);
  if (!num) {
    label = String.fromCharCode(label.charCodeAt(0) + position);
  } else {
    label = String.fromCharCode(label.charCodeAt(0) + (position % 26)) + num;
  }
  return label;
};

const addReportObject =
  (object: InspectorReportObject) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const report = getState().report;
    const objects = report.objects;
    if (!objects) return;

    object.label = getLabel(objects.length);

    const template = object.template || report.template;

    template?.sections?.forEach((section) => {
      section.items?.forEach((item) => {
        report.parameters.push({
          id: uuidv4(),
          itemId: item.id,
          objectId: object.id,
          timeEstimate: 0,
          priceEstimate: 0,
          hidden: false,
          craftsmen: [],
          label: item.label,
          name: item.name,
          note: "",
          pictures: [],
          value: "",
          descriptions: [],
          groupItemId: item.groupId,
        });
      });
    });

    dispatch({
      type: Report.ADD_REPORT_OBJECT,
      payload: object,
    });
  };

const changeObject =
  (id: string, key: string, value: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({
      type: Report.CHANGE_REPORT_OBJECT,
      payload: { id, key, value },
    });
  };

const addPicture =
  (image: PictureRef) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    dispatch({ type: Report.UPLOAD_PICTURE });

    const currentReportId = getState().report.id;
    const common = getState().common;
    // const hubConnection = common.hubConnection;

    if (image.data) {
      try {
        // if (currentReportId !== NIL) {
        //   const canUpdate = await hubConnection?.invoke("Lock", currentReportId, common.deviceId);
        //   if (!canUpdate) {
        //     await dispatch(attachToSync());
        //   }
        // }

        image.featureId = currentReportId;
        const result = await ReportService.uploadPicture({
          id: image.id,
          featureId: currentReportId,
          data: image.data,
          objectId: image.objectId,
          deviceId: common.deviceId,
        });
        image.url = result.url;
        image.data = "";

        await dispatch({ type: Report.UPLOAD_PICTURE_SUCCEEDED });
      } catch (error: unknown) {
        console.error("Error upload image file:", error);
        dispatch({ type: Report.UPLOAD_PICTURE_FAILED });
        // handleError(error as string | AppError, dispatch);
        // throw error;
      }
    }
    dispatch({
      type: Report.ADD_PICTURE,
      payload: image,
    });

    await dispatch(applyLocalChanges());
    // await hubConnection?.invoke("Unlock", currentReportId, common.deviceId);
  };

const removePicture =
  (picture: PictureRef) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const common = getState().common;
    // const hubConnection = common.hubConnection;
    const currentReport = getState().report;

    dispatch({
      type: Report.REMOVE_PICTURE,
      payload: picture.id,
    });

    if (!picture.data && picture.url) {
      try {
        // if (currentReport.id !== NIL) {
        //   const canUpdate = await hubConnection?.invoke("Lock", currentReport.id, common.deviceId);
        //   if (!canUpdate) {
        //     await dispatch(attachToSync());
        //   }
        // }

        if (currentReport.pictures.some((x) => x.id === picture.id)) {
          await ReportService.removePicture({
            id: picture.id,
            featureId: currentReport.id,
            objectId: picture.objectId,
            deviceId: common.deviceId,
          });
        } else {
          await ReportService.removeDescriptionPicture({
            id: picture.id,
            featureId: currentReport.id,
            objectId: picture.objectId,
            itemId: picture.itemId,
            deviceId: common.deviceId,
          });
        }
      } catch (error: unknown) {
        handleError(error as string | AppError, dispatch);
      }
    }

    await dispatch(applyLocalChanges());
    // await hubConnection?.invoke("Unlock", currentReport.id, common.deviceId);
  };

const updateHousePic =
  (imageData: PictureRef) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    dispatch({ type: Report.UPLOAD_PICTURE });
    const common = getState().common;

    const currentReportId = getState().report.id;
    imageData.featureId = currentReportId;

    if (imageData.data) {
      try {
        const result = await ReportService.uploadHousePicture({
          id: imageData.id,
          reportId: currentReportId,
          data: imageData.data,
          deviceId: common.deviceId,
        });

        dispatch({ type: Report.UPLOAD_PICTURE_SUCCEEDED });

        imageData.url = result.url;
        imageData.data = "";
      } catch (error: unknown) {
        console.error("Error upload image file:", error);
        dispatch({ type: Report.UPLOAD_PICTURE_FAILED });
      }
    }

    dispatch({
      type: Report.UPDATE_REPORT_HOUSE_PICTURE,
      payload: imageData.data === "" ? imageData.url : imageData.data,
    });

    await dispatch(applyLocalChanges());
  };

const getCustomerReportList =
  (filter: any, skip: number | undefined, take: number | undefined) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({ type: Common.GET_LIST });
    try {
      const result = await ReportService.getCustomerReportList(filter, skip, take);
      dispatch({
        type: Report.GET_CUSTOMER_REPORT_LIST_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error: unknown) {
      dispatch({
        type: Common.GOT_ERROR,
        payload: error,
        error: true,
      });
    }
  };

const getCustomerReport = (reportId: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Report.GET_REPORT });
  try {
    const report = await ReportService.getCustomerReport(reportId);
    if (!report) {
      throw new Error("Rapport ikke fundet");
    }

    const state = flattenReportTree(report);

    dispatch({ type: Report.GET_REPORT_SUCCEEDED, payload: state });
    return report;
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch, {
      type: Report.GET_REPORT_FAILED,
      payload: error,
    });
  }
};

const addNewTemplateItem =
  (objectId: string, item: TemplateItem) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const report = getState().report;
    if (!report.objects?.length) return;

    const object = report.objects.find((x) => x.id === objectId);
    if (object && !object.template) {
      dispatch({
        type: Report.ADD_TEMPLATE_TO_OBJECT,
        payload: { objectId },
      });
    }

    dispatch({
      type: Report.ADD_NEW_TEMPLATE_ITEM,
      payload: { objectId, item },
    });
  };

const updateTemplateItem =
  (objectId: string, item: TemplateItem) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const report = getState().report;
    if (!report.objects?.length) return;

    const object = report.objects.find((x) => x.id === objectId);
    if (object && !object.template) {
      dispatch({ type: Report.ADD_TEMPLATE_TO_OBJECT, payload: { objectId } });
    }

    dispatch({ type: Report.UPDATE_TEMPLATE_ITEM, payload: { objectId, item } });
  };

const addNewParameter =
  (item: InspectorReportParameter) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const report = getState().report;
    if (!report.objects?.length) return;

    dispatch({
      type: Report.ADD_NEW_PARAMETER_ITEM,
      payload: item,
    });
  };

const removeParameter = (itemId: string) => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Report.REMOVE_PARAMETER_ITEM, payload: itemId });
};

const toggleHiddenSection =
  (objectId: string, sectionId: string) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const report = getState().report;
    if (!report.objects?.length) return;

    const object = report.objects.find((x) => x.id === objectId);
    if (object && !object.template) {
      dispatch({
        type: Report.ADD_TEMPLATE_TO_OBJECT,
        payload: { objectId },
      });
    }
    if (object && Object.hasOwn(object.sectionEvaluations, sectionId)) {
      dispatch(evaluateSection(objectId, sectionId, 0));
    }

    dispatch({
      type: Report.CHANGE_SECTION_HIDDEN_STATE,
      payload: { objectId, sectionId },
    });
  };

const updateObjectTemplate =
  (objectId: string, template: TemplateModel) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({
      type: Report.UPDATE_OBJECT_TEMPLATE,
      payload: { objectId, template },
    });
  };

const applyLocalChanges = () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
  const report = getState().report;
  const needToRestoreAutoSave = report.autoSave;
  if (report.autoSave) {
    dispatch({ type: Report.AUTOSAVE });
  }
  await updateLocalData(LocalStorageItems.Report, report);
  if (needToRestoreAutoSave) {
    dispatch({ type: Report.AUTOSAVE });
  }
};

const saveDoc = (reportId: string, pdf: File) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: "UPLOAD_PDF" });
  try {
    if (pdf.type !== "application/pdf") throw new Error("Not a pdf file");

    const fd = new FormData();
    fd.append(pdf.name + ".pdf", pdf);

    const result = await ApiService.postFormData<string>(`/api/report/pdf/${reportId}`, fd);

    dispatch({ type: "UPLOAD_PDF_SUCCEEDED" });
    return result;
  } catch (error: unknown) {
    handleError(error as AppError, dispatch);
  }
};

const updateMaintenanceInfo =
  () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const report = getState().report;
    const objects = report.objects;
    const maintenanceData: MaintenanceInfo[] = [];
    const sections: MaintenanceInfoSection[][] = [];

    if (report.objects?.length) {
      objects.forEach((object) => {
        sections.push(
          (object.template || report.template)?.sections
            ?.filter((x) => !x.hidden)
            .map((x) => {
              return {
                id: x.id,
                name: x.name,
                label: x.label,
                itemsId: x.items?.filter((b) => !b.autoHideItems).map((a) => a.id),
                itemsGroupId: x.items
                  ?.filter((q) => q.groupId && !q.autoHideItems)
                  .map((a) => a.groupId)
                  .filter((y, i, a) => a.indexOf(y) === i),
                objectId: object.id,
                objectName: object.name,
              };
            })
        );
      });
    }

    const emptyValues = report.template?.options?.filter((x) => !x.worth)?.map((x) => x.value);

    if (sections?.length) {
      sections.forEach((sectionElement) => {
        for (let i = 0; i < sectionElement.length; i++) {
          if (sectionElement[i] && sectionElement[i]?.itemsId) {
            const parametersInfo = report.parameters.filter(
              (x) =>
                !emptyValues.includes(x.value) &&
                x.priceEstimate !== 0 &&
                (x.groupItemId
                  ? sectionElement[i].itemsGroupId?.includes(x.groupItemId)
                  : sectionElement[i].itemsId?.includes(x.itemId)) &&
                sectionElement[i].objectId === x.objectId
            );

            if (parametersInfo.length) {
              maintenanceData.push({
                sectionId: sectionElement[i]?.id,
                objectId: sectionElement[i]?.objectId,
                objectName: sectionElement[i]?.objectName,
                sectionName: sectionElement[i].name,
                itemsId: sectionElement[i].itemsId,
                [TimeToFinish.Now]: parametersInfo
                  .filter((x) => x.timeEstimate === TimeToFinish.Now)
                  .reduce((sum, item) => {
                    //this multiplication is due to the requirements in the task
                    //https://dev.azure.com/cdmua/Klimaplan-myHouse/_boards/board/t/Klimaplan-myHouse%20Team/Stories/?workitem=1403
                    //It was decided the sum of each item will be multiplied on the number of craftsmen added to the item
                    return sum + item.priceEstimate * item.craftsmen.length;
                  }, 0),
                [TimeToFinish.OneToThreeYears]: parametersInfo
                  .filter((x) => x.timeEstimate === TimeToFinish.OneToThreeYears)
                  .reduce((sum, item) => {
                    return sum + item.priceEstimate * item.craftsmen.length;
                  }, 0),
                [TimeToFinish.ThreeToFiveYears]: parametersInfo
                  .filter((x) => x.timeEstimate === TimeToFinish.ThreeToFiveYears)
                  .reduce((sum, item) => {
                    return sum + item.priceEstimate * item.craftsmen.length;
                  }, 0),
                totalSumAll: parametersInfo.reduce((sum, item) => {
                  return sum + item.priceEstimate;
                }, 0),
                parameters: parametersInfo,
              });
            }
          }
        }
      });
    }

    dispatch({
      type: Report.UPDATE_REPORT_MAINTENANCEINFO,
      payload: maintenanceData,
    });
  };

const addDescription =
  (itemId: string, desc: ParameterDescription) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({
      type: Report.ADD_DESCRIPTION,
      payload: { itemId, desc },
    });
  };

const removeDescription =
  (itemId: string, descriptionId: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({
      type: Report.REMOVE_DESCRIPTION,
      payload: { itemId, descriptionId },
    });
  };

const changeDescription =
  (itemId: string, descriptionId: string, value: string) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({
      type: Report.CHANGE_DESCRIPTION,
      payload: { itemId, descriptionId, value },
    });
  };

const addDescriptionPicture =
  (itemId: string, descriptionId: string, pic: PictureRef) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    await dispatch({ type: Report.UPLOAD_PICTURE });
    const currentReportId = getState().report.id;
    const common = getState().common;
    // const hubConnection = common.hubConnection;

    if (pic.data) {
      try {
        // if (currentReportId !== NIL) {
        //   const canUpdate = await hubConnection?.invoke("Lock", currentReportId, common.deviceId);
        //   if (!canUpdate) {
        //     await dispatch(attachToSync());
        //   }
        // }

        pic.featureId = currentReportId;
        const result = await ReportService.uploadDescriptionPicture({
          id: pic.id,
          featureId: currentReportId,
          objectId: pic.objectId,
          itemId: itemId,
          descriptionId: descriptionId,
          data: pic.data,
          deviceId: common.deviceId,
        });

        //create new picture object with the same structure as picture object from the server
        const newPic: PictureRef = {
          id: pic.id,
          featureId: pic.featureId,
          objectId: pic.objectId,
          itemId: itemId,
          url: result.url,
        };

        pic = newPic;

      } catch (error: unknown) {
        console.error("Error upload image file:", error);
        dispatch({ type: Report.UPLOAD_PICTURE_FAILED });
        // handleError(error as string | AppError, dispatch);
        // throw error;
      }
    }

    await dispatch({
      type: Report.ADD_DESCRIPTION_PHOTO,
      payload: { itemId, descriptionId, pic },
    });

    await dispatch({ type: Report.UPLOAD_PICTURE_SUCCEEDED });
    await dispatch(applyLocalChanges());
    // await hubConnection?.invoke("Unlock", currentReportId, common.deviceId);
  };

const hideGroupValues =
  (sectionId: string, exceptId: string) =>
  async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const reportState = getState().report;
    const param = reportState.parameters.find((x) => x.id === exceptId);
    if (!param?.groupItemId) return;
    const groupParam = reportState.parameters.find((x) => x.itemId === param.groupItemId);

    const object = reportState.objects.find((x) => x.id === param?.objectId);
    const template = object?.template || reportState.template;

    const groupItem = template.sections
      .reduce((acc, s) => [...acc, ...s.items], [] as TemplateItem[])
      .find((x) => x.id === groupParam?.itemId);
    if (!groupItem?.autoHideItems) return;
    const ids: string[] = [];
    reportState.parameters
      .filter((x) => x.objectId === object?.id && x.id !== exceptId && !x.value && x.groupItemId === groupParam?.itemId)
      .forEach((p) => {
        ids.push(p.id);
      });

    dispatch({ type: Report.HIDE_GROUP_VALUES, payload: ids });
  };

const transferReport =
  (employeeId: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const report = getState().report;
    if (report.id === NIL) return;

    return await ReportService.transferReport(report.id, employeeId);
  };

const assignReport =
  (reportId: string, employeeId: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    try {
      await ReportService.transferReport(reportId, employeeId);
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch);
    }
  };

const removeReport = (reportId: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    await ReportService.removeReport(reportId);
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};

// export const synchronizeReport =
//   () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
//     const reportState = getState().report;
//     const common = getState().common;
//     //possible fix of double call. Dispatch of Report.OUT_OF_SYNC should be only here
//     if (!reportState.sync) return;
//     dispatch({ type: Report.OUT_OF_SYNC });

//     const hubConnection = common.hubConnection;
//     for (let i = 0; i < 10; i++) {
//       const unlocked = await hubConnection?.invoke("Accept", reportState.id, common.deviceId);
//       if (unlocked) {
//         break;
//       }
//       await sleep(1000);
//       if (process.env.NODE_ENV === "development") console.log("wait 1 sec");
//     }

//     try {
//       const report = await ReportService.getReport(reportState.id);
//       if (report) {
//         const merged = mergeReportState(reportState, flattenReportTree(report));
//         dispatch({
//           type: Report.GET_REPORT_SUCCEEDED,
//           payload: merged,
//         });
//       }
//       dispatch({ type: Report.SYNCHRONIZED });
//     } catch (error: unknown) {
//       handleError(error as string | AppError, dispatch, {
//         type: Report.GET_REPORT_FAILED,
//         payload: error,
//         error: true,
//       });
//     }
//   };

// export const attachToSync =
//   () => async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
//     const common = getState().common;
//     const hubConnection = common.hubConnection;
//     const reportId = getState().report.id;
//     if (reportId !== NIL) {
//       const session = await hubConnection?.invoke<DeviceSession>("StartSync", reportId, common.deviceId);

//       if (session?.stale) {
//         await dispatch(synchronizeReport());
//       }
//     }
//   };

const isNeedToSave = (current: ReportState, updated: ReportState): boolean => {
  //Important!!! Need to add all properties which exist only in state or only in downloaded report. In other case it can cause merge errors
  const propsToExclude = [
    "init",
    "loading",
    "uploadProgress",
    "needSave",
    "count",
    "sync",
    "autoSave",
    "collapseAll",
    "created",
    "modified",
    "pdfLink",
    "pictures",
    "newCustomParamIds",
    "isMerged",
    "error",
    "parameters",
    "loadingPicture",
    "allowedToEdit",
    "objects",
  ];

  const newPics = [
    ...current.pictures.filter((c) => !updated.pictures.some((u) => c.id === u.id)),
    ...updated.pictures.filter((u) => !current.pictures.some((c) => c.id === u.id)),
  ];

  const newParameters = [
    ...current.parameters.filter((c) => !updated.parameters.some((u) => c.id === u.id)),
    ...updated.parameters.filter((u) => !current.parameters.some((c) => c.id === u.id)),
  ];

  //because of the lodash library cannot compare nested properties of objects correct
  // we compare properties of parameters of report separately
  if (newParameters.length > 0) return true;

  //exclude parameters properties which will be compared separately or are not important for comparison
  const propsToExcludeForParams = [
    "descriptions",
    "pictures",
    "priceEstimateNoVat",
    "description",
    "isReadOnly",
    "groupItemId",
  ];

  const isChangesInParametersExist = current.parameters.some((cp) => {
    const updatedParam = updated.parameters.find((up) => up.id === cp.id);

    if (!updatedParam) return true;

    const currentParamForCompare = _.omit(cp, propsToExcludeForParams); // current reports parameters without exuded properties
    const updatedParamForCompare = _.omit(updatedParam, propsToExcludeForParams); // updated reports parameters without exuded properties
    const isEqualParam = _.isEqual(currentParamForCompare, updatedParamForCompare);

    if (!isEqualParam) return true;

    const notUploadedPictures = updatedParam.pictures.some((u) => u.data);
    if (notUploadedPictures) return true;

    const newDescriptions = [
      ...cp.descriptions.filter((c) => !updatedParam.descriptions.some((u) => c.id === u.id)),
      ...updatedParam.descriptions.filter((u) => !cp.descriptions.some((c) => c.id === u.id)),
    ];

    if (newDescriptions.length > 0) return true;

    return cp.descriptions.some((cd) => {
      const updatedDesc = updatedParam.descriptions.find((ud) => ud.id === cd.id);

      if (!updatedDesc) return true;

      if (cd.pictures && cd.pictures?.length > 0 && updatedDesc.pictures && updatedDesc.pictures.length > 0) {
        const newDescriptionsPictures = [
          ...cd.pictures.filter((c) => !cd.pictures?.some((u) => c.id === u.id)),
          ...updatedDesc.pictures.filter((u) => !cd.pictures?.some((c) => c.id === u.id)),
        ];

        if (!newDescriptionsPictures) return true;

        const notUploadedDescriptionsPictures = updatedDesc.pictures.filter((u) => u.data);
        if (notUploadedDescriptionsPictures.length > 0) return true;
      }
      if (cd.description !== updatedDesc.description) return true;
    });
  });

  //for correct comparison report object properties which are not set or empty and also can be undefined need to use custom  comparator
  const customObjectComparator = (value: any, other: any) => {
    if (typeof value === "object" && typeof other === "object") {
      if (value?.groupId === "" && other?.groupId === undefined) return true;
      if (value?.groupId === undefined && other?.groupId === "") return true;
    }
    return undefined;
  };

  //comparison of report object using custom  comparator
  const objectsAreEqual = _.isEqualWith(current.objects, updated.objects, customObjectComparator);

  const currentForCompare = _.omit(current, propsToExclude);
  const updatedForCompare = _.omit(updated, propsToExclude);
  const isEqual = _.isEqual(currentForCompare, updatedForCompare);
  return !isEqual || newPics.length > 0 || isChangesInParametersExist || !objectsAreEqual;
};

const mergeReportState = (current: ReportState, updated: ReportState) => {
  if (current.id !== updated.id) return current;

  const newObjects = [
    ...current.objects.filter((c) => !updated.objects.some((u) => c.id === u.id)),
    ...updated.objects.filter((u) => !current.objects.some((c) => c.id === u.id)),
  ];

  updated.objects
    .filter((o) => current.objects.some((c) => c.id === o.id))
    .forEach((o) => {
      const cur = current.objects.find((x) => x.id === o.id);
      const newObj = { ...o, ...cur, template: _.cloneDeep(cur?.template) };
      newObjects.push(newObj);
    });

  const newPics = [...current.pictures.filter((p) => p.data), ...updated.pictures];

  const newParams: InspectorReportParameter[] = []; // [...updated.parameters.filter((u) => !current.parameters.some((c) => c.id === u.id))];

  //find parameters which was created in current report while it was saving
  const unloadedCurrentParameters = current.parameters.filter(
    (cp) => !updated.parameters.some((up) => cp.id === up.id)
  );

  updated.parameters.forEach((u) => {
    const cur = current.parameters.find((x) => x.id === u.id);
    const newParam = { ...u, ...cur };
    const descriptions: ParameterDescription[] = [];

    u.descriptions.forEach((d) => {
      //copy a local description to the new one if there are the same id in the updated report
      const curDesc = cur?.descriptions.find((cd) => cd.id === d.id);

      const currentPic: PictureRef[] = [];
      //add url to picture from updated description picture
      if (curDesc) {
        if (curDesc.pictures?.some((p) => p.data)) {
          _.cloneDeep(curDesc.pictures).forEach((cp) => {
            const up = d.pictures?.find((p) => p.id === cp.id);

            if (cp.data && up && up.url) {
              const newPic: PictureRef = {
                id: cp.id,
                featureId: cp.featureId,
                objectId: cp.objectId,
                itemId: cp.itemId,
                url: up.url,
              };
              currentPic.push(newPic);
            } else {
              currentPic.push(cp);
            }
          });
        }

        descriptions.push({
          id: curDesc.id,
          description: curDesc.description,
          pictures: currentPic.length ? currentPic : _.cloneDeep(curDesc.pictures),
        });
      } else {
        descriptions.push(_.cloneDeep(d));
      }
    });

    const localDescriptions = cur?.descriptions.filter((d) => !u.descriptions.some((ud) => ud.id === d.id)) || [];
    newParam.descriptions = [...localDescriptions, ...descriptions];
    newParams.push(newParam);
  });

  return {
    ...current,
    ...updated,
    data: _.cloneDeep(updated.data),
    objects: newObjects,
    pictures: newPics,
    parameters: [...newParams, ...unloadedCurrentParameters],
  };
};

const toggleAutoSave = () => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Report.AUTOSAVE });
};

const addToWishList =
  (param: { id: string; label: string; priceEstimate: number }) =>
  (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    if (!param.id) {
      param.id = uuidv4();
    }

    dispatch({ type: Report.ADD_TO_WISHLIST, payload: param });
  };

const removeFromWishList = (id: string) => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Report.REMOVE_FROM_WISHLIST, payload: id });
};

const evaluateSection =
  (objectId: string, sectionId: string, value: number) => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({ type: Report.EVALUATE_SECTION, payload: { objectId, sectionId, value } });

    dispatch(updateEvaluation());
  };

const updateEvaluation = () => (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
  const report = getState().report;
  const avg = calculateEvaluation(report.objects || []);
  dispatch({ type: Report.CALCULATE_EVALUATION, payload: avg });
};

const setCollapseAll = (value: boolean) => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  dispatch({ type: Report.COLLAPSE_ALL, payload: value });
};

export const getReportWishList = async (id: string) => {
  const response = await ApiService.get<CustomerReportWishList>(`/api/report/wishes/${id}`);
  return response;
};

export const submitReportWishList = async (id: string, wishList: WishListParameter[]) => {
  const response = await ApiService.post(`/api/report/wishes/${id}`, { wishList });
  return response;
};

export const updateWishListFromCustomer =
  (wishList: WishListParameter[]) => (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    if (wishList) {
      dispatch({ type: Report.UPDATE_WISHLIST_FROM_CUSTOMER, payload: wishList });
    }
  };

const enableEditing =
  (request: TransferReportToDeviceCommand) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    dispatch({ type: Report.ENABLE_EDITING });
    try {
      await ApiService.post(`/api/report/edit`, request);
      dispatch({ type: Report.ENABLE_EDITING_SUCCEEDED });
    } catch (error: unknown) {
      handleError(error as string | AppError, dispatch);
      dispatch({ type: Report.ENABLE_EDITING_FAILED });
    }
  };

const closeReport = (reportId: string) => async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
  try {
    await ApiService.post(`/api/report/close/${reportId}`);
  } catch (error: unknown) {
    handleError(error as string | AppError, dispatch);
  }
};
