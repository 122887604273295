import {
  Grid,
  TextField,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  CircularProgress,
} from "@mui/material";
import React, { Ref, useEffect, useReducer, useRef } from "react";
import { AddressOption } from "../interfaces/frontend";
import ReportService from "../services/ReportService";
import ErrorBoundary from "./ErrorBoundary";

type AddressAutocompleteState = {
  filter: string;
  options: AddressOption[];
  selected: AddressOption | null;
  loading: boolean;
  open: boolean;
};

const initialState: AddressAutocompleteState = {
  filter: "",
  options: [],
  selected: null,
  loading: false,
  open: false,
};

const reducer = (state: AddressAutocompleteState, action: { type: keyof AddressAutocompleteState; payload: any }) => {
  return { ...state, [action.type]: action.payload };
};

export type AutocompleteRef = {
  clear: () => void;
};

const AddressAutocomplete =
  // forwardRef(
  (props: { selectedAction: (selected: AddressOption) => void }, ref?: Ref<AutocompleteRef>) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const activeFetchesRef = useRef(0);

    const abortRef = useRef<AbortController | undefined>();

    const changeFilter = (event: React.ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
      dispatch({ type: "filter", payload: value });
      if (reason === "input") {
        dispatch({ type: "loading", payload: true });
      }
      if (reason === "clear") {
        dispatch({ type: "selected", payload: null });
      }
    };

    // const clear = () => {
    //   dispatch({ type: "selected", payload: null });
    // };

    const openAutocomplete = () => {
      dispatch({ type: "open", payload: true });
    };

    const closeAutocomplete = () => {
      dispatch({ type: "open", payload: false });
    };

    const changeValue = (
      event: React.ChangeEvent<unknown>,
      value: AddressOption | null,
      reason: AutocompleteChangeReason
    ) => {
      switch (reason) {
        case "selectOption":
          dispatch({ type: "selected", payload: value });
          if (value && value.value) {
            props.selectedAction(value);
            dispatch({ type: "open", payload: false });
          }
          break;
        case "removeOption":
          dispatch({ type: "selected", payload: null });
          break;
      }
    };

    const fetchOptions = async (value: string, cursorPosition?: number) => {
      if (abortRef.current) {
        abortRef.current.abort();
      }
      dispatch({ type: "loading", payload: true });
      activeFetchesRef.current += 1;

      const controller = new AbortController();
      abortRef.current = controller;

      if (value) {
        try {
          const options = await ReportService.getAutocompleteValues(value, cursorPosition || 0, controller.signal);
          dispatch({ type: "options", payload: options });
        } catch (error) {
          if (controller.signal.aborted) {
            return;
          }
        } finally {
          // decrement activeFetchesRef after current fetch will be completed
          activeFetchesRef.current -= 1;

          //  set load to false only if there are no more active fetches
          if (activeFetchesRef.current === 0) {
            dispatch({ type: "loading", payload: false });
          }
        }
      } else {
        activeFetchesRef.current -= 1;
        if (activeFetchesRef.current === 0) {
          dispatch({ type: "loading", payload: false });
        }
      }
      abortRef.current = undefined;
    };

    useEffect(() => {
      const asyncFetchOptions = async () => {
        fetchOptions(state.filter, state.selected?.cursorPosition);
      };
      asyncFetchOptions();
      return () => {
        //
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.selected, state.filter]);

    return (
      <ErrorBoundary errorMessage="Autocomplete error">
        <Grid item container>
          <Autocomplete
            fullWidth
            id="address-autocomplete"
            noOptionsText={"Ikke fundet"}
            loadingText={"Indlæser..."}
            loading={state.loading}
            value={state.selected}
            isOptionEqualToValue={(o, v) => {
              return o.houseData.houseId === v.houseData.houseId;
            }}
            onInputChange={changeFilter}
            onChange={changeValue}
            getOptionLabel={(option) => option?.label || ""}
            options={state.options}
            open={state.open}
            onOpen={openAutocomplete}
            onClose={closeAutocomplete}
            disableCloseOnSelect
            renderInput={(params) => (
              <form>
                <TextField
                  autoComplete="off"
                  placeholder="Indtast adresse"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {state.loading && (
                          <CircularProgress style={{ position: "absolute", right: 50 }} color="inherit" size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              </form>
            )}
          />
        </Grid>
      </ErrorBoundary>
    );
  };
// );

export default AddressAutocomplete;
